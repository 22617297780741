export function saveToLocalStorage(key: string, data: object | null | string | number, expirationMinutes = 60) {
  try {
    const expiration_ms = expirationMinutes * 60 * 1000;
    const record = {
      value: JSON.stringify(data),
      timestamp: new Date().getTime() + expiration_ms,
    };

    const serializedRecord = JSON.stringify(record);
    localStorage.setItem(key, serializedRecord);

    return data;
  } catch (err) {
    console.log('Could not save to locale storage. Check your browser settings!');
    return null;
  }
}

export function loadFromLocalStorage(key: string) {
  try {
    const serializedRecord = localStorage.getItem(key);
    if (serializedRecord === null) {
      return undefined;
    }
    const record = JSON.parse(serializedRecord);
    if (new Date().getTime() > record.timestamp) {
      return undefined;
    }

    let res = JSON.parse(record.value);
    if (!res) {
      localStorage.removeItem(key);
      res = undefined;
    }
    return res;
  } catch (err) {
    console.log('Error loading state from local storage. Please check your browser settings !');
    return undefined;
  }
}
