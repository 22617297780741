import { CustomTheme } from '../../types';

const Tooltip = (theme: CustomTheme) => ({
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      arrow: {
        color: theme.palette.common.black,
      },
    },
  },
});

export default Tooltip;
