import { createSlice } from '@reduxjs/toolkit';
import { getConnectorInitialState } from 'utils/models/connector';

const initialState = {
  stationDetails: {
    id: '',
    securityCode: '',
    serialNumber: '',
    manufacturer: '',
    articleNumber: '',
  },
  networkDetails: {
    wiFiSupported: false,
    gsmSupported: false,
  },
  connectors: [{ ...getConnectorInitialState() }],
  activeStep: 0,
};

export const importStationStepSlice = createSlice({
  name: 'importStationStep',
  initialState: {
    data: initialState,
  },
  reducers: {
    setStationDetails: {
      reducer: (state, action) => {
        state.data.stationDetails = {
          ...state.data.stationDetails,
          ...action.payload,
        };
      },
    },
    setNetworkDetails: {
      reducer: (state, action) => {
        state.data.networkDetails = {
          ...state.data.networkDetails,
          ...action.payload,
        };
      },
    },
    setConnectorDetails: {
      reducer: (state, action) => {
        state.data.connectors = action.payload;
      },
    },
    resetImportStationStep: {
      reducer: (state) => {
        state.data = initialState;
      },
    },
    increaseImportStationStep: {
      reducer: (state, action) => {
        const stepSize = 1;
        state.data.activeStep += stepSize;
      },
    },
    decreaseImportStationStep: {
      reducer: (state) => {
        const stepSize = 1;
        state.data.activeStep -= stepSize;
      },
    },
  },
});

export const {
  setStationDetails,
  setNetworkDetails,
  setConnectorDetails,
  resetImportStationStep,
  increaseImportStationStep,
  decreaseImportStationStep,
} = importStationStepSlice.actions;

export default importStationStepSlice.reducer;
