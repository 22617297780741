import { alpha } from '@mui/material/styles';
import { CustomTheme } from '../../types';

const CssBaseline = (theme: CustomTheme) => ({
  MuiCssBaseline: {
    styleOverrides: {
      '*': {
        boxSizing: 'border-box',
        '*::-webkit-scrollbar': {
          width: '4px',
          height: '8px',
        },
        '*::-webkit-scrollbar-thumb': {
          background: alpha(theme.palette.grey[400], 0.33),
        },
      },
      html: {
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
      },
      '#root': {
        height: '100%',
      },
    },
  },
});

export default CssBaseline;
