import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function ButtonSelection({ itemSelected, setItemSelected, listOptions, startText = '' }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value) => {
    setItemSelected(value);
    setAnchorEl(null);
  };
  if (!itemSelected) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {startText}
        {t(itemSelected.label).toLowerCase()}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {listOptions.map((el) => (
          <MenuItem onClick={() => handleMenuItemClick(el)} key={el.value}>
            {t(el.label)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
