import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const createDriver = createAsyncThunk(
  'driver/createDriver',
  async (
    { lastName, firstName, email, phone, rfIds, seeAllStations, hasMobilePrivateAccess, clientId, onSuccessCallback },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosRequest.post('/drivers', {
        details: { lastName, firstName, email, phone, hasMobilePrivateAccess, seeAllStations: seeAllStations ?? false },
        rfIds,
        clientId,
      });
      onSuccessCallback && onSuccessCallback();
      return { lastName, firstName, email, phone, hasMobilePrivateAccess, rfIds, clientId };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchDriver = createAsyncThunk('driver/fetchDriver', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await axiosRequest.get(`/drivers/${id}`);
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const updateDriver = createAsyncThunk(
  'driver/updateDriver',
  async (
    { id, details, rfIds, clientId, clientName, seeAllStations, updateSeeAllStations, onSuccessCallback },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosRequest.put(`/drivers/${id}`, {
        details,
        rfIds,
        clientId,
      });
      const result = { details, rfIds, clientId, clientName };

      if (updateSeeAllStations) {
        await axiosRequest.put(`/drivers/${id}/seeallstations/${seeAllStations}`, {});
        result.details.seeAllStations = seeAllStations;
      }

      onSuccessCallback && onSuccessCallback(result);
      return { ...result };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteDriver = createAsyncThunk(
  'driver/deleteDriver',
  async ({ id, onSuccessCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.delete(`/drivers/${id}`);
      onSuccessCallback && onSuccessCallback();
      return {};
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const driverSlice = createSlice({
  name: 'drivers',
  initialState: {
    driver: {},
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    resetDriver: {
      reducer: (state, action) => {
        state.driver = {};
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDriver.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(createDriver.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.driver = { ...state.driver, ...action.payload };
      })
      .addCase(createDriver.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // fetch driver
      .addCase(fetchDriver.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchDriver.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.driver = { ...state.driver, ...action.payload };
      })
      .addCase(fetchDriver.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update driver
      .addCase(updateDriver.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateDriver.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.driver = {
          ...state.driver,
          ...action.payload,
          details: {
            ...state.driver.details,
            ...action.payload.details,
          },
        };
      })
      .addCase(updateDriver.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // deleteDriver
      .addCase(deleteDriver.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(deleteDriver.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.driver = {};
      })
      .addCase(deleteDriver.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetDriver } = driverSlice.actions;

export default driverSlice.reducer;
