import { useState } from 'react';
import { SxProps, Typography, TypographyProps } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../themes/defaultTheme';

interface IProps {
  text: string;
  variant: Variant;
  sx: any;
}

export const ViewMoreText = ({ text, variant, sx }: IProps) => {
  const { t } = useTranslation();
  const [isPartial, setIsPartial] = useState(true);

  const maxTextLength = 2000;

  const onToggle = () => {
    setIsPartial((prev) => !prev);
  };

  return (
    <Typography variant={variant} sx={[{ wordWrap: 'break-word' }, { ...sx, fontSize: '14px' }]}>
      {isPartial ? text.slice(0, maxTextLength) : text}

      {text?.length > maxTextLength && (
        <>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
          <span onClick={onToggle} style={{ color: theme.palette.primary.main, fontSize: '14px' }}>
            {isPartial ? ` ...${t('global.action.readMore')}` : ` ...${t('global.action.readLess')}`}
          </span>
        </>
      )}
    </Typography>
  );
};
