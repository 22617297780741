import { useMemo, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { Box, SxProps } from '@mui/material';
import { CustomSelect } from '../input/CustomSelect';
import { changeLocale, getLocale } from '../../../utils/i18n/i18n';
import { getIdByCountryCode, localeCountries } from '../../../utils/i18n/helper';

export const LocaleSelector = ({ sx = {} }: { sx?: SxProps }) => {
  const [locale, setLocale] = useState(getLocale());

  const onChangeLocale = (e: SelectChangeEvent) => {
    setLocale(e.target.value);
    changeLocale(e.target.value).then(() => {
      window.location.reload();
    });
  };

  const items = useMemo(() => {
    return localeCountries.map((country) => {
      const id = getIdByCountryCode(country);

      return {
        id,
        label: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.75 }}>
            <img
              src={`https://flagcdn.com/w20/${country.abbr.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${country.abbr.toLowerCase()}.png 2x`}
              alt={country.code}
            />
            <span>{id.toUpperCase()}</span>
          </Box>
        ),
      };
    });
  }, []);

  return (
    <CustomSelect
      items={items}
      onChange={onChangeLocale}
      value={locale}
      containerSx={{ width: 90 }}
      sx={{ ...sx }}
      label={undefined}
      validation={undefined}
      valueDirty={undefined}
      setValueDirty={undefined}
    />
  );
};
