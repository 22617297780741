import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchStationTerminalActions = createAsyncThunk(
  'station/fetchStationTerminalActions',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/cs/${id}/actions`);

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const executeStationTerminalAction = createAsyncThunk(
  'station/executeStationTerminalAction',
  async ({ id, label, payloadValue, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.put(`/cs/${id}/actions/${label}`, payloadValue, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      onSuccessCallback && onSuccessCallback();
      return response.data;
    } catch (e) {
      onErrorCallback && onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const stationTerminalSlice = createSlice({
  name: 'stationTerminalActions',
  initialState: {
    stationTerminal: {
      actionsList: {},
      executionResult: {},
    },
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    removeExecutionResult(state) {
      state.stationTerminal.executionResult = {};
    },
  },
  extraReducers: (builder) => {
    builder

      // fetchStationTerminalActions
      .addCase(fetchStationTerminalActions.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchStationTerminalActions.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.stationTerminal.actionsList = action.payload;
      })
      .addCase(fetchStationTerminalActions.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // executeStationTerminalAction
      .addCase(executeStationTerminalAction.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(executeStationTerminalAction.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.stationTerminal.executionResult = action.payload;
      })
      .addCase(executeStationTerminalAction.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { removeExecutionResult } = stationTerminalSlice.actions;

export default stationTerminalSlice.reducer;
