import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchSmartChargingDetails = createAsyncThunk(
  'smartCharging/fetchSmartChargingDetails',
  async ({ smartChargingId }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/groups/${smartChargingId}/details`);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const smartChargingDetailsSlice = createSlice({
  name: 'smartChargingDetails',
  initialState: {
    smartChargingDetails: {},
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      // fetch smart charging details
      .addCase(fetchSmartChargingDetails.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchSmartChargingDetails.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.smartChargingDetails = action.payload;
      })
      .addCase(fetchSmartChargingDetails.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default smartChargingDetailsSlice.reducer;
