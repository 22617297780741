import { CustomTheme } from '../../types';

const ListItemIcon = (theme: CustomTheme) => ({
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 35,
        color: theme.palette.common.white,
      },
    },
  },
});

export default ListItemIcon;
