import { Box, Stack } from '@mui/material';

import { DateTimeFiltersPicker } from 'components/common/tables/DateTimeFiltersPicker';
import { DateTimeFiltersShort } from 'components/common/tables/DateTimeFiltersShort';

export const DateTimeFilter = ({ column }: any) => (
  <>
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <DateTimeFiltersPicker column={column} type="from" />
      <DateTimeFiltersPicker column={column} type="to" />
    </Box>
    <Stack direction="row" spacing={1.5} sx={{ mt: '10px' }}>
      <DateTimeFiltersShort intervalText="1D" nrDays={1} column={column} />
      <DateTimeFiltersShort intervalText="1W" nrDays={7} column={column} />
      <DateTimeFiltersShort intervalText="1M" nrDays={30} column={column} />
      <DateTimeFiltersShort intervalText="3M" nrDays={60} column={column} />
      <DateTimeFiltersShort intervalText="6M" nrDays={90} column={column} />
      <DateTimeFiltersShort intervalText="1Y" nrDays={365} column={column} />
    </Stack>
  </>
);
