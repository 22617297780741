// sets null when is set dynamically
export const breadcrumbsDictionary = (id: string | number, secondId: string | number | undefined = undefined) => ({
  '/': 'pages.title.dashboard',
  '/dashboard': 'pages.title.dashboard',
  '/charging-sessions': 'pages.title.chargingSessions',
  '/error-logs': 'pages.title.errorLogs',

  // assets
  '/locations': 'pages.title.locations',
  '/locations/create': 'pages.title.create',
  [`/locations/${id}`]: null,
  [`/locations/${id}/stations`]: 'pages.title.stations',
  [`/locations/${id}/charging-sessions`]: 'pages.title.chargingSessions',
  [`/locations/${id}/error-logs`]: 'pages.title.errorLogs',
  '/map': 'pages.title.map',

  '/authorizations': 'pages.title.authorizations',
  [`/authorizations/${id}`]: null,
  [`/authorizations/${id}/charging-sessions`]: 'pages.title.chargingSessions',
  '/authorizations/activate': 'pages.title.activateCards',
  '/authorizations/activate/single': 'pages.title.activateSingleCard',
  '/authorizations/activate/multiple': 'pages.title.activateMultipleCards',
  '/authorizations/import': 'pages.title.importCards',
  '/authorizations/import/single': 'pages.title.importSingleCard',
  '/authorizations/import/multiple': 'pages.title.importMultipleCards',
  '/authorizations/import-activate': 'pages.title.importAndActivateCards',
  '/authorizations/import-and-activate': 'pages.title.importAndActivateCard',

  '/stations': 'pages.title.stations',
  [`/stations/${id}`]: null,
  [`/stations/${id}/details`]: 'pages.title.details',
  [`/stations/${id}/pricing`]: 'pages.title.pricing',
  [`/stations/${id}/location`]: 'pages.title.location',
  [`/stations/${id}/settings`]: 'pages.title.settings',
  [`/stations/${id}/charging-sessions`]: 'pages.title.chargingSessions',
  [`/stations/${id}/logs`]: 'pages.title.logs',
  [`/stations/${id}/configuration`]: 'pages.title.configuration',
  [`/stations/${id}/terminal`]: 'pages.title.terminal',
  '/stations/activate': 'pages.title.activateStations',
  '/stations/activate/single': 'pages.title.activateSingleStation',
  '/stations/activate/multiple': 'pages.title.activateMultipleStations',
  '/stations/import-and-activate': 'pages.title.importAndActivateStation',

  '/charging-profiles': 'pages.title.chargingProfiles',
  '/charging-profiles/create': 'pages.title.chargingProfileCreate',
  [`/charging-profiles/${id}`]: null,

  '/load-balancing': 'pages.title.loadBalancing',
  '/load-balancing/create': 'pages.title.createLoadBalancing',
  [`/load-balancing/${id}`]: null,
  [`/load-balancing/${id}/update`]: 'pages.title.update',

  '/tariffs': 'pages.title.tariffs',
  '/tariffs/create': 'pages.title.createTariff',
  [`/tariffs/${id}`]: null,

  '/stations/import': 'pages.title.importStations',
  '/stations/import/single': 'pages.title.importSingleStation',
  '/stations/import/multiple': 'pages.title.importMultipleStations',
  '/stations/import-activate': 'pages.title.importAndActivateMultipleStations',

  '/stations/models': 'pages.title.stationModels',
  '/stations/models/create': 'pages.title.createStationModel',
  [`/stations/models/${id}`]: null,
  [`/stations/models/${id}/update`]: 'pages.title.update',

  '/drivers': 'pages.title.drivers',
  [`/drivers/${id}`]: null,
  [`/drivers/${id}/billing`]: 'pages.title.billing',
  [`/drivers/${id}/charging-sessions`]: 'pages.title.chargingSessions',
  [`/drivers/${id}/authorizations`]: 'pages.title.authorizations',

  '/drivers/register': 'pages.title.registerDrivers',
  '/drivers/register/manual': 'pages.title.registerDriver',
  '/drivers/register/invite': 'pages.title.inviteDriver',
  '/drivers/billing': 'pages.title.billing',

  '/subsidiaries': 'pages.title.subsidiaries',
  '/subsidiaries/create': 'pages.title.createSubsidiary',

  '/clients': 'pages.title.clients',
  [`/clients/${id}`]: null,
  [`/clients/${id}/stations`]: 'pages.title.stations',
  [`/clients/${id}/authorizations`]: 'pages.title.authorizations',
  [`/clients/${id}/drivers`]: 'pages.title.drivers',
  [`/clients/${id}/users`]: 'pages.title.users',
  [`/clients/${id}/charging-sessions`]: 'pages.title.chargingSessions',
  [`/clients/${id}/users/${secondId}`]: null,

  [`/clients/${id}/billing`]: 'pages.title.billing',
  [`/clients/${id}/billing-plan`]: 'pages.title.billingPlan',
  '/clients/register': 'pages.title.registerClients',
  '/clients/register/individual': 'pages.title.registerIndividualClient',
  '/clients/register/invite/individual': 'pages.title.inviteIndividualClient',
  '/clients/register/company': 'pages.title.registerCompanyClient',
  '/clients/register/invite/company': 'pages.title.inviteCompanyClient',
  '/clients/register/invite': 'pages.title.invite',
  '/clients/users': 'pages.title.users',
  [`/clients/users/${id}`]: null,
  '/clients/users/add-client-user': 'pages.title.addUser',

  // partners
  '/partners': 'pages.title.partners',
  '/partners/users': 'pages.title.users',
  '/partners/register': 'pages.title.registerPartners',
  '/partners/register/invite': 'pages.title.invitePartner',
  '/partners/register/form': 'pages.title.registerPartner',
  [`/partners/${id}`]: null,
  [`/partners/users/${id}`]: null,
  '/partners/users/add-partner-user': 'pages.title.addUser',
  [`/partners/${id}/stations`]: 'pages.title.stations',
  [`/partners/${id}/authorizations`]: 'pages.title.authorizations',
  [`/partners/${id}/drivers`]: 'pages.title.drivers',
  [`/partners/${id}/charging-sessions`]: 'pages.title.chargingSessions',
  [`/partners/${id}/billing-plan`]: 'pages.title.billingPlan',
  [`/partners/${id}/billing`]: 'pages.title.billing',

  '/charging-apps': 'pages.title.chargingApplications',

  '/company': 'pages.title.companyDetails',
  '/company/users': 'pages.title.users',
  [`/company/users/${id}`]: null,
  '/company/users/add-users': 'pages.title.addUsers',

  '/company/roles': 'pages.title.roles',
  '/company/billing': 'pages.title.billing',
  '/company/billing/plan': 'pages.title.billingPlan',
  '/company/billing/invoices': 'pages.title.invoices',
  '/company/customization': 'pages.title.customization',

  '/support': 'pages.title.support',
  '/terms-and-conditions': 'pages.title.termsAndConditions',

  '/upgrade': 'pages.title.upgrade',

  '/my-profile': 'pages.title.myProfile',

  '/billing-drivers': 'pages.title.driverInvoices',
  '/billing-clients': 'pages.title.clientsInvoices',
});
