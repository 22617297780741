import { theme } from '../themes/defaultTheme';

export const convertStyleMetricToNumber = (styleValue: string): number => {
  return Number(styleValue.replace('px', '').replace('rem', '').replace('em', ''));
};

export const isStyleLowerSize = (styleValue: string, compareValue: string): boolean => {
  return convertStyleMetricToNumber(styleValue) < convertStyleMetricToNumber(compareValue);
};

export const getFormSpacing = (
  currentSpacing: string | undefined
): {
  spacing: string;
  titlePaddingBottom: string | number;
} => {
  const spacing = !currentSpacing ? theme.customSpacing.spacingInsideForm : currentSpacing;
  const titlePaddingBottom = isStyleLowerSize(spacing, theme.customSpacing.spacingInsideForm) ? spacing : 0;

  return { spacing, titlePaddingBottom };
};
