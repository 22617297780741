import { CustomTheme } from '../../types';

const ToggleButton = (theme: CustomTheme) => ({
  MuiToggleButton: {
    styleOverrides: {
      root: {
        '& .MuiSvgIcon-root': {
          color: theme.palette.customColors.toggleButton,
        },
      },
    },
  },
});

export default ToggleButton;
