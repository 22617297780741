import { IPricingProfile } from './PricingProfile';

export enum ICurrency {
  RON = 'RON',
  EUR = 'EUR',
  USD = 'USD',
  ARS = 'ARS',
}

export const currencyOptions = [
  { id: ICurrency.RON, value: ICurrency.RON, label: ICurrency.RON },
  { id: ICurrency.EUR, value: ICurrency.EUR, label: ICurrency.EUR },
  { id: ICurrency.USD, value: ICurrency.USD, label: ICurrency.USD },
  { id: ICurrency.ARS, value: ICurrency.ARS, label: ICurrency.ARS },
];

export type IPrice = Partial<IPricingProfile>;
