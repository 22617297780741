import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { BiDotsVerticalRounded } from 'react-icons/bi';

import Box from '@mui/material/Box';
import { CircularProgress, ClickAwayListener, IconButton, Popper, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/../system';
import { fetchNotifications, resetNotifications, setAllNotificationsRead } from 'redux/slices/notificationsSlice';
import { defaultTabsStyle } from 'themes/defaultTheme';
import { notificationTypes } from 'utils/models/notification';
import { useDebounce } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
import NotificationItem from './NotificationItem';

const BoxShadowMenu = styled('div')(({ theme }) => ({
  border: '1px solid rgb(224, 227, 231)',
  boxShadow: 'rgb(170 180 190 / 30%) 0px 4px 20px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'white',
}));

export function Notifications({ onSelectItem }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications.notifications);
  const hasMore = useSelector((state) => state.notifications.hasMore);

  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [distanceBottom, setDistanceBottom] = useState(0);
  const listEl = useRef();
  const [markAllAsReadEl, setMarkAllAsReadEl] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(
      fetchNotifications({
        onSuccessCallback: () => {
          setIsLoading(false);
        },
      })
    );

    return () => {
      dispatch(resetNotifications());
    };
  }, [dispatch]);

  const resetDefault = (index = 0) => {
    setCurrentNotificationIndex(index);
    setCurrentPage(0);
    setIsLoading(false);
    setDistanceBottom(0);
    dispatch(resetNotifications());
  };

  const onTabChange = (event, index) => {
    resetDefault(index);

    let read;
    let notificationType = notificationTypes[index].value;

    // unread notifications
    if (index === 1) {
      read = false;
      notificationType = 'all';
    }

    setIsLoading(true);
    dispatch(fetchNotifications({ notificationType, read, onSuccessCallback: () => setIsLoading(false) }));
  };

  const getNextNotifications = useDebounce(() => {
    let read;
    let notificationType = notificationTypes[currentNotificationIndex].value;

    // unread notifications
    if (currentNotificationIndex === 1) {
      read = false;
      notificationType = 'all';
    }

    dispatch(
      fetchNotifications({
        notificationType,
        read,
        page: currentPage + 1,
        onSuccessCallback: () => {
          setIsLoading(false);
        },
      })
    );
    setCurrentPage((prevState) => prevState + 1);
  });

  const scrollListener = useCallback(() => {
    const bottom = listEl.current.scrollHeight - listEl.current.clientHeight;
    if (!distanceBottom) {
      setDistanceBottom(Math.round((bottom / 100) * 20));
    }
    if (listEl.current.scrollTop > bottom - distanceBottom && hasMore) {
      getNextNotifications();
      setIsLoading(true);
    }
  }, [hasMore, distanceBottom, getNextNotifications]);

  useLayoutEffect(() => {
    listEl.current.addEventListener('scroll', scrollListener);
    return () => {
      listEl.current.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener]);

  const handleOptionsClick = (event) => {
    setMarkAllAsReadEl(markAllAsReadEl ? null : event?.currentTarget);
  };

  const handleMarkAllAsRead = () => {
    handleOptionsClick({});
    dispatch(
      setAllNotificationsRead({
        notificationType: notificationTypes[currentNotificationIndex].value,
      })
    );
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        paddingTop="32px"
        paddingLeft="16px"
        paddingRight="16px"
      >
        <Typography sx={{ display: 'block', fontSize: 28 }}>{t('pages.title.notifications')}</Typography>

        <IconButton aria-label="view options" color="inherit" onClick={handleOptionsClick} padding={0}>
          <BiDotsVerticalRounded sx={{ fontSize: 32, color: 'text.primary' }} />
        </IconButton>

        <Popper
          id="mark-all-popper"
          open={Boolean(markAllAsReadEl)}
          anchorEl={markAllAsReadEl}
          style={{ zIndex: 9999 }}
        >
          <ClickAwayListener onClickAway={handleOptionsClick}>
            <BoxShadowMenu sx={{ p: 1 }} onClick={handleMarkAllAsRead}>
              <Typography sx={{ cursor: 'pointer', fontSize: 14 }}>
                {t('notifications.markAsReadType', {
                  type: t(notificationTypes[currentNotificationIndex].label),
                })}
              </Typography>
            </BoxShadowMenu>
          </ClickAwayListener>
        </Popper>
      </Box>

      <Tabs
        value={currentNotificationIndex}
        onChange={onTabChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          ...defaultTabsStyle,
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingBottom: '16px',
        }}
      >
        {notificationTypes.map((notificationType) => {
          return <Tab label={t(notificationType.label)} key={notificationType.value} />;
        })}
      </Tabs>
      <List
        sx={{
          overflow: 'auto',
          maxWidth: '100%',
          minHeight: '30vh',
          padding: 0,
        }}
        ref={listEl}
      >
        {notifications?.map((notification, index) => {
          return (
            <NotificationItem
              notification={notification}
              key={notification.id}
              isLastItem={index === notifications.length - 1}
              onSelectItem={onSelectItem}
            />
          );
        })}

        {!isLoading && !notifications?.length && (
          <Typography sx={{ textAlign: 'center', mt: 4 }}>{t('notifications.noNotifications')}</Typography>
        )}

        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress color="inherit" size={32} />
          </Box>
        )}
      </List>
    </Box>
  );
}
