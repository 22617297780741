import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { IManufacturer, ReduxStatusEnum } from 'types';

interface IState {
  manufacturers: IManufacturer[];
  manufacturer: IManufacturer;
  status: ReduxStatusEnum;
  error: unknown | string | null;
}

const initialState: IState = {
  manufacturers: [],
  manufacturer: {} as IManufacturer,
  status: ReduxStatusEnum.IDLE,
  error: null,
};

export const fetchManufacturers = createAsyncThunk<IManufacturer[], { text?: string }>(
  'manufacturers/fetchManufacturers',
  async ({ text }) => {
    if (!text?.length) {
      const response = await axiosRequest.get(`/stationSpecs/manufacturers`);
      return response.data.map((item: string) => {
        return {
          id: `${item}`,
          name: item,
        };
      });
    }
    const response = await axiosRequest.get(`/stationSpecs/manufacturers/${text}`);
    return response.data.map((item: string) => {
      return {
        id: `${item}`,
        name: item,
      };
    });
  }
);

export const manufacturersSlice = createSlice({
  name: 'manufacturers',
  initialState: { ...initialState },
  reducers: {
    selectManufacturer: (state, action: PayloadAction<{ name: string }>) => {
      state.manufacturer = {
        id: action.payload.name,
        name: action.payload.name,
      };
    },
    resetManufacturer: (state) => {
      state.manufacturer = {} as IManufacturer;
    },
  },
  extraReducers: (builder) => {
    builder

      // manufacturers
      .addCase(fetchManufacturers.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchManufacturers.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.manufacturers = action.payload;
      })
      .addCase(fetchManufacturers.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetManufacturer, selectManufacturer } = manufacturersSlice.actions;

export default manufacturersSlice.reducer;
