import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchStationsAnalytics = createAsyncThunk(
  'mainDashboard/fetchStationsAnalytics',
  async ({}, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get('/reports/hours');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const stationsAnalyticsSlice = createSlice({
  name: 'stationsAnalytics',
  initialState: {
    stationsAnalytics: {},
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStationsAnalytics.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchStationsAnalytics.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.stationsAnalytics = action.payload;
      })
      .addCase(fetchStationsAnalytics.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default stationsAnalyticsSlice.reducer;
