import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export function FooterButtonSaveCancel({
  disabled = false,
  onSave,
  onCancel,
  isLoading = false,
  sx = {},
  saveColor = 'primary',
  saveText = 'global.action.save',
  cancelText = 'global.action.cancel',
  cancelColor = 'inherit',
}) {
  const { t } = useTranslation();
  if (disabled) return null;

  return (
    <Box sx={[sx]}>
      {onCancel && (
        <Button onClick={onCancel} color={cancelColor} sx={{ mr: 2 }} disabled={isLoading}>
          {t(cancelText)}
        </Button>
      )}
      <Button variant="contained" color={saveColor} size="small" onClick={onSave} disabled={isLoading}>
        {t(saveText)}
      </Button>
    </Box>
  );
}
