const FormLabel = () => ({
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: 18,
      },
    },
  },
});

export default FormLabel;
