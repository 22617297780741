import { CustomTheme } from '../../types';

const Button = (theme: CustomTheme) => ({
  MuiButton: {
    styleOverrides: {
      root: {
        paddingTop: theme.spacing(),
      },
      sizeLarge: {
        fontSize: 18,
      },
      sizeMedium: {
        fontSize: 14,
      },
      sizeSmall: {
        minWidth: 120,
        fontSize: 13,
      },
    },
  },
});

export default Button;
