import { SxProps } from '@mui/material';

export interface IStyling {
  isFlexRow?: boolean;
  showBottomDivider?: boolean;
  spacing?: string;
  labelCustomStyle?: SxProps;
  valueCustomStyle?: SxProps;
  hideLastDivider?: boolean;
  size?: 'lg' | 'md';
}
export const defaultStyling: IStyling = {
  isFlexRow: false,
  showBottomDivider: false,
};
