import { axiosRequest } from '../axiosRequest';

export const getStations = async () => {
  const response = await axiosRequest.get(`/stations`);
  return response.data;
};

export const getStationById = async (stationId: string) => {
  const response = await axiosRequest.get(`/stations/${stationId}`);
  return response.data;
};

export const getStationByIdBySecurityCode = async (stationId: string, securityCode: string) => {
  const response = await axiosRequest.get(`/stations/${stationId}/code/${securityCode}`);
  return response.data;
};

export const getStationsById = async (stationId: string) => {
  const response = await axiosRequest.get(`/register/stationIds/${stationId ?? ''}`);
  return response.data;
};

export const getStationsByLocationId = async (locationId: number) => {
  const response = await axiosRequest.get(`/locations/${locationId}/stations`);
  return response.data?.stations ?? [];
};

export const getQrCode = async (stationId: string) => {
  const response = await axiosRequest.get(`/stations/${stationId}/qrcode`);
  return response.data;
};
