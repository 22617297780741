import { TextField } from '@mui/material';
import * as React from 'react';
import { ChangeEvent, useRef } from 'react';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { IInputText } from '../../../types';

type IProps = IInputText;
export const BasicInput = ({
  placeholder,
  value,
  setValue,
  valueDirty,
  setValueDirty,
  validation,
  extraError,
  extraErrorMessage,
  setExtraError,
  type = 'text',
  onEnterPress,
  disabled,
  sx,
  inputProps,
}: IProps) => {
  const input = useRef();

  const extraProps: TextFieldProps = {};

  // if (type === 'password') {
  //   extraProps.inputProps = { inputMode: 'numeric', pattern: '[0-9]|(.)*' };
  // }

  if (inputProps) {
    extraProps.inputProps = { ...extraProps.inputProps, ...inputProps };
  }

  if (setValueDirty) {
    extraProps.onBlur = () => setValueDirty(true);
  }

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setExtraError && setExtraError(false);
    setValue(e);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onEnterPress) {
      onEnterPress();
    }
  };

  return (
    <TextField
      inputRef={input}
      disabled={disabled}
      placeholder={placeholder}
      value={value ?? ''}
      onChange={onInputChange}
      required={!!validation}
      error={validation ? (valueDirty && !validation?.isValid) || extraError : false}
      helperText={(valueDirty && !validation?.isValid && validation?.error) || (extraError && extraErrorMessage)}
      variant="outlined"
      type={type}
      size="small"
      margin="dense"
      sx={{
        width: '100%',
        ...sx,
      }}
      InputProps={{ notched: false, sx: { borderRadius: 1 }, onKeyDown }}
      FormHelperTextProps={{ style: { marginLeft: 0 } }}
      {...extraProps}
    />
  );
};
