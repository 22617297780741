import { Box } from '@mui/material';
import React from 'react';
import { theme } from 'themes/defaultTheme';

export function DefaultBox({ children }) {
  return (
    <Box
      sx={{
        p: theme.spacing(6),
        backgroundColor: 'common.white',
        borderRadius: 1,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        boxShadow: 27,
        [theme.breakpoints.down('sm')]: {
          borderRadius: 0,
          boxShadow: 0,
          p: theme.spacing(4),
          pt: theme.spacing(6),
        },
      }}
    >
      {children}
    </Box>
  );
}
