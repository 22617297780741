import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchBillingDrivers = createAsyncThunk(
  'billingDrivers/fetchBillingDrivers',
  async ({}, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get('/billing/invoices/full/charge');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const billingDriversSlice = createSlice({
  name: 'billingDrivers',
  initialState: {
    billingDrivers: [],
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    resetBillingDrivers: (state) => {
      state.billingDrivers = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBillingDrivers.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchBillingDrivers.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.billingDrivers = action.payload;
      })
      .addCase(fetchBillingDrivers.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetBillingDrivers } = billingDriversSlice.actions;

export default billingDriversSlice.reducer;
