import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import AuthLayout from 'layout/Auth/AuthLayout';
import Loadable from 'components/Loadable';

const LoginPage = Loadable(lazy(() => import('pages/Auth/Login/LoginPage')));
const ResetPasswordPage = Loadable(lazy(() => import('pages/Auth/ResetPassword/ResetPasswordPage')));
const ForgotPasswordPage = Loadable(lazy(() => import('pages/Auth/ForgotPassword/ForgotPasswordPage')));

const RegisterIndividualPage = Loadable(lazy(() => import('pages/Auth/Register/Individual/RegisterIndividualPage')));
const RegisterCompanyPage = Loadable(lazy(() => import('pages/Auth/Register/Company/RegisterCompanyPage')));

const RegisterPartnerPage = Loadable(lazy(() => import('pages/Auth/Register/Partner/RegisterPartnerPage')));

const LoginRoutes = {
  path: '/auth/',
  element: <AuthLayout />,
  children: [
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'reset-password',
      element: <ResetPasswordPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPasswordPage />,
    },
    {
      path: 'register/individual',
      element: <RegisterIndividualPage />,
    },
    {
      path: 'register/company',
      element: <RegisterCompanyPage />,
    },
    {
      path: 'register/partner',
      element: <RegisterPartnerPage />,
    },
    {
      path: '*', // 404
      element: <Navigate to="/auth/login" replace />,
    },
  ],
};
export default LoginRoutes;
