import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from '../../../api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from '../../../utils';

export const fetchRegisterStations = createAsyncThunk(
  'stations/fetchRegisterStations',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.post('/register/stations', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const saveRegisterStations = createAsyncThunk(
  'stations/saveRegisterStations',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.post('/register/stations/save', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return {};
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const resetRegisterStations = createAsyncThunk('stations/resetRegisterStations', async () => {
  return {};
});

export const RegisterStationsSlice = createSlice({
  name: 'registerStations',
  initialState: {
    registerStations: {},
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchRegisterStations
      .addCase(fetchRegisterStations.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchRegisterStations.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.registerStations = action.payload;
      })
      .addCase(fetchRegisterStations.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // saveRegisterStations
      .addCase(saveRegisterStations.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(saveRegisterStations.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.registerStations = action.payload;
      })
      .addCase(saveRegisterStations.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // resetRegisterStations
      .addCase(resetRegisterStations.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(resetRegisterStations.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.registerStations = action.payload;
      })
      .addCase(resetRegisterStations.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default RegisterStationsSlice.reducer;
