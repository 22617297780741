import { createSlice } from '@reduxjs/toolkit';
import { locationInitialState } from 'utils/models/location';

const initialState = {
  stationDetails: {
    id: '',
    securityCode: '',
    name: '',
    clientId: '',
    client: {
      id: '',
      label: '',
    },
  },
  location: locationInitialState,
  locationTab: 0,
  pricingDetails: {
    chargingType: 'public',
    monthlyPayment: undefined,
    prices: [
      {
        currency: 'RON',
        price: undefined,
        startPrice: undefined,
      },
    ],
    connectors: [],
  },
  billingPlan: {},
  activeStep: 0,
};

export const registerStationStepSlice = createSlice({
  name: 'registerStationStep',
  initialState: {
    data: initialState,
  },
  reducers: {
    setStationDetails: {
      reducer: (state, action) => {
        state.data.stationDetails = {
          ...state.data.stationDetails,
          ...action.payload,
        };
      },
    },
    setLocationRegisterStep: {
      reducer: (state, action) => {
        state.data.location = action.payload;
      },
    },
    setLocationTabRegisterStep: {
      reducer: (state, action) => {
        state.data.locationTab = action.payload.locationTab;
      },
    },
    setBillingInformationRegisterStationStep: {
      reducer: (state, action) => {
        state.data.billingInformation = action.payload;
      },
    },
    setPricingDetailsRegisterStationStep: {
      reducer: (state, action) => {
        state.data.pricingDetails = action.payload;
      },
    },
    resetRegisterStationStep: {
      reducer: (state) => {
        state.data = initialState;
      },
    },
    increaseRegisterStationStep: {
      reducer: (state, action) => {
        const stepSize = 1;

        state.data.activeStep += stepSize;
      },
    },
    decreaseRegisterStationStep: {
      reducer: (state) => {
        const stepSize = 1;
        state.data.activeStep -= stepSize;
      },
    },
  },
});

export const {
  setBillingInformationRegisterStationStep,
  setStationDetails,
  setLocationTabRegisterStep,
  resetRegisterStationStep,
  increaseRegisterStationStep,
  decreaseRegisterStationStep,
  setLocationRegisterStep,
  setPricingDetailsRegisterStationStep,
} = registerStationStepSlice.actions;

export default registerStationStepSlice.reducer;
