import InputLabel from 'themes/overrides/InputLabel';
import OutlinedInput from 'themes/overrides/OutlinedInput';
import Autocomplete from './Autocomplete';
import Button from './Button';
import CssBaseline from './CssBaseline';
import Drawer from './Drawer';
import FormLabel from './FormLabel';
import ListItemButton from './ListItemButton';
import ListItemIcon from './ListItemIcon';
import ListItemText from './ListItemText';
import SvgIcon from './SvgIcon';
import Switch from './Switch';
import TableBody from './TableBody';
import ToggleButton from './ToggleButton';
import Tooltip from './Tooltip';
import TabScrollButton from './TabScrollButton';
import Tabs from './Tabs';
import Select from './Select';
import { CustomTheme } from '../../types';

const ComponentsOverrides = (theme: CustomTheme) => ({
  ...Autocomplete(),
  ...Button(theme),
  ...CssBaseline(theme),
  ...Drawer(theme),
  ...FormLabel(),
  ...InputLabel(theme),
  ...ListItemButton(theme),
  ...ListItemIcon(theme),
  ...ListItemText(theme),
  ...OutlinedInput(theme),
  ...SvgIcon(theme),
  ...Switch(theme),
  ...TableBody(),
  ...ToggleButton(theme),
  ...Tooltip(theme),
  ...TabScrollButton(theme),
  ...Tabs(theme),
  ...Select(),
});

export default ComponentsOverrides;
