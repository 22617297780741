import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isVisible: false,
  onClose: null,
  buttonColor: 'primary',
  buttonText: 'Save',
  title: '',
  getContent: null,
  onSubmit: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: {
      reducer: (state, action) => {
        state.isVisible = true;
        state.onClose = action.payload.onClose ?? initialState.onClose;
        state.title = action.payload.title ?? initialState.title;
        state.getContent = action.payload.getContent ?? initialState.getContent;
        state.onSubmit = action.payload.onSubmit ?? initialState.onSubmit;
        state.buttonColor = action.payload.buttonColor ?? initialState.buttonColor;
        state.buttonText = action.payload.buttonText ?? initialState.buttonText;
      },
    },
    hideModal: {
      reducer: (state, action) => {
        state.isVisible = initialState.isVisible;
        state.onClose = initialState.onClose;
        state.title = initialState.title;
        state.getContent = initialState.getContent;
        state.onSubmit = initialState.onSubmit;
        state.buttonColor = initialState.buttonColor;
        state.buttonText = initialState.buttonText;
      },
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;

export default modalSlice.reducer;
