import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchImportStations = createAsyncThunk(
  'stations/fetchImportStations',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.post('/import/stations', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const saveImportStations = createAsyncThunk(
  'stations/saveImportStations',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.post('/import/stations/save', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return {};
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const resetImportStations = createAsyncThunk('stations/resetImportStations', async () => {
  return {};
});

export const importStationsSlice = createSlice({
  name: 'importStations',
  initialState: {
    importStations: {},
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchImportStations
      .addCase(fetchImportStations.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchImportStations.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.importStations = action.payload;
      })
      .addCase(fetchImportStations.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // saveImportStations
      .addCase(saveImportStations.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(saveImportStations.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.importStations = action.payload;
      })
      .addCase(saveImportStations.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // resetImportStations
      .addCase(resetImportStations.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(resetImportStations.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.importStations = action.payload;
      })
      .addCase(resetImportStations.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default importStationsSlice.reducer;
