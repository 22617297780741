import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchBillingPay = createAsyncThunk('billingPay/fetchBillingPay', async ({}, { rejectWithValue }) => {
  try {
    const response = await axiosRequest.get('/billing/invoices/full/subscription/pay');
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const billingPaySlice = createSlice({
  name: 'billingPay',
  initialState: {
    billingPay: [],
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    resetBillingPay: (state) => {
      state.billingPay = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBillingPay.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchBillingPay.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.billingPay = action.payload;
      })
      .addCase(fetchBillingPay.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetBillingPay } = billingPaySlice.actions;

export default billingPaySlice.reducer;
