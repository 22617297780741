import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isVisible: false,
};

export const windowLoaderSlice = createSlice({
  name: 'windowLoader',
  initialState,
  reducers: {
    showWindowLoader: {
      reducer: (state, action) => {
        state.isVisible = true;
      },
    },
    hideWindowLoader: {
      reducer: (state, action) => {
        state.isVisible = false;
      },
    },
  },
});

export const { showWindowLoader, hideWindowLoader } = windowLoaderSlice.actions;

export default windowLoaderSlice.reducer;
