import { CustomTypography } from '../types';

export const customTypography = {
  fontFamily: 'Poppins, sans-serif',
  h1: {
    fontSize: 48,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h2: {
    fontSize: 36,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h3: {
    fontSize: 32,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h4: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h5: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h6: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  body1: {},
  body2: {},
  subtitle1: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: 1.75,
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: 1.57,
  },
  caption: {
    fontWeight: 500,
  },
  overline: {
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 1,
    lineHeight: 1.66,
  },
  button: {
    fontWeight: 500,
    textTransform: 'none',
  },
  subtitle3: {
    fontSize: 13.5,
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: 1.5,
  },
  subtitle4: {
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 1.5,
  },
  textSm: {
    fontSize: 14,
  },
} as Partial<CustomTypography>;
