import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { locationInitialState } from '../../../utils/models/location';
import { axiosRequest } from '../../../api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from '../../../utils';

// NOT USED
export const createCompanyDetails = createAsyncThunk('company/createCompanyDetails', async ({ data }) => {
  // const response = await axiosRequest.post(
  // 	`/stations/${stationId}`, {details, location, billing}
  // )
  return { id: 1, ...data };
});

export const getCompanyDetails = createAsyncThunk('company/getCompanyDetails', async ({}, { rejectWithValue }) => {
  try {
    const response = await axiosRequest.get(`/clients/tenant`);
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const updateCompanyDetails = createAsyncThunk('company/updateCompanyDetails', async ({ id, data }) => {
  // const response = await axiosRequest.post(
  // 	`/stations/${stationId}`, {details, location, billing}
  // )
  return { id, ...data };
});

export const updateCompanyLocation = createAsyncThunk('company/updateCompanyLocation', async ({ id, data }) => {
  // const response = await axiosRequest.post(
  // 	`/stations/${stationId}`, {details, location, billing}
  // )
  return { id, ...data };
});

export const companyDetailsSlice = createSlice({
  name: 'companyDetails',
  initialState: {
    companyDetails: {
      id: null,
      name: '',
      email: '',
      phone: '',
      vatNumber: '',
      location: locationInitialState,
    },
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // create companyDetails
      .addCase(createCompanyDetails.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(createCompanyDetails.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.companyDetails = { ...action.payload };
      })
      .addCase(createCompanyDetails.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(getCompanyDetails.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(getCompanyDetails.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.companyDetails = { ...action.payload };
      })
      .addCase(getCompanyDetails.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(updateCompanyDetails.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateCompanyDetails.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.companyDetails = { ...state.companyDetails, ...action.payload };
      })
      .addCase(updateCompanyDetails.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(updateCompanyLocation.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateCompanyLocation.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.companyDetails.location = { ...action.payload };
      })
      .addCase(updateCompanyLocation.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default companyDetailsSlice.reducer;
