import { CustomSpacingTheme, CustomTheme } from 'types';

export const customSpacing = (theme: CustomTheme) =>
  ({
    spacing1: theme.spacing(1),
    spacing2: theme.spacing(2),
    spacing3: theme.spacing(3),
    spacing4: theme.spacing(4),
    spacing5: theme.spacing(5),
    spacing6: theme.spacing(6),
    spacing7: theme.spacing(7),
    spacing8: theme.spacing(8),
    spacing9: theme.spacing(9),
    verticalBetweenElements: theme.spacing(2),
    paddingAroundInside: theme.spacing(2),
    marginAroundOutside: theme.spacing(3),
    spacingInsideCard: theme.spacing(5),
    spacingInsideForm: theme.spacing(4),
    spacingInsideFormSmall: theme.spacing(2.25),
    spacingBetweenText: theme.spacing(5),
  }) as CustomSpacingTheme;
