import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { IStationModel, ReduxStatusEnum } from 'types';

interface IState {
  stationsModels: IStationModel[];
  status: ReduxStatusEnum;
  error: unknown | string | null;
}

const initialState: IState = {
  stationsModels: [],
  status: ReduxStatusEnum.IDLE,
  error: null,
};

export const fetchStationsModels = createAsyncThunk<IStationModel[]>(
  'stationsModels/fetchStationsModels',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get('/stationSpecs');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteStationModel = createAsyncThunk<void, { stationModelId: string; onSuccessCallback?: () => void }>(
  'stationsModels/deleteStationModel',
  async ({ stationModelId, onSuccessCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.delete(`/stationSpecs/${stationModelId}`);
      onSuccessCallback && onSuccessCallback();
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const stationsModelsSlice = createSlice({
  name: 'stationsModels',
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetch stations models
      .addCase(fetchStationsModels.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchStationsModels.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.stationsModels = action.payload;
      })
      .addCase(fetchStationsModels.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // delete stations model
      .addCase(deleteStationModel.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(deleteStationModel.fulfilled, (state) => {
        state.status = ReduxStatusEnum.SUCCESS;
      })
      .addCase(deleteStationModel.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      });
  },
});

export default stationsModelsSlice.reducer;
