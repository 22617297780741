import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchRegisterCards = createAsyncThunk(
  'cards/fetchRegisterCards',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.post('/register/cards', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const saveRegisterCards = createAsyncThunk('cards/saveRegisterCards', async (formData, { rejectWithValue }) => {
  try {
    const response = await axiosRequest.post('/register/cards/save', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return {};
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const resetRegisterCards = createAsyncThunk('cards/resetRegisterCards', async () => {
  return {};
});

export const registerCardsSlice = createSlice({
  name: 'registerCards',
  initialState: {
    registerCards: {},
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchRegisterCards
      .addCase(fetchRegisterCards.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchRegisterCards.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.registerCards = action.payload;
      })
      .addCase(fetchRegisterCards.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // saveRegisterCards
      .addCase(saveRegisterCards.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(saveRegisterCards.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.registerCards = action.payload;
      })
      .addCase(saveRegisterCards.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // resetRegisterCards
      .addCase(resetRegisterCards.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(resetRegisterCards.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.registerCards = action.payload;
      })
      .addCase(resetRegisterCards.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default registerCardsSlice.reducer;
