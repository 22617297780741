import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { getStationSpecsByArticleNumber } from 'api/requests/stationSpecsRequest';
import { IArticleNumber, IStationModel, ReduxStatusEnum } from 'types';

interface IArticleNumbersState {
  articleNumbers: IArticleNumber[];
  selectedArticleNumber: IArticleNumber | null;
  stationSpecs: IStationModel | null;
  status: ReduxStatusEnum;
  error: unknown | string | null;
}

const initialState: IArticleNumbersState = {
  articleNumbers: [],
  selectedArticleNumber: null,
  stationSpecs: null,
  status: ReduxStatusEnum.IDLE,
  error: null,
};

export const fetchArticleNumbers = createAsyncThunk<IArticleNumber[]>(
  'articleNumbers/fetchArticleNumbers',
  async () => {
    const response = await axiosRequest.get(`/stationSpecs/articleNumbers`);
    return response.data.map((item: string) => {
      return {
        id: `${item}`,
        name: item,
      } as IArticleNumber;
    });
  }
);

export const fetchArticleNumbersByManufacturerId = createAsyncThunk<IArticleNumber[], { manufacturedId: string }>(
  'articleNumbers/fetchArticleNumbersByManufacturerId',
  async ({ manufacturedId }) => {
    const response = await axiosRequest.get(`/stationSpecs/articleNumbersByManufacturer/${manufacturedId}`);
    return response.data.map((item: string) => {
      return {
        id: `${item}`,
        name: item,
      };
    });
  }
);

export const fetchArticleNumbersByModelId = createAsyncThunk<
  IArticleNumber[],
  { manufacturerName: string; modelName: string }
>('articleNumbers/fetchArticleNumbersByModelId', async ({ modelName, manufacturerName }) => {
  const response = await axiosRequest.get(
    `/stationSpecs/articleNumbersByManufacturerAndModel/${encodeURIComponent(manufacturerName)}/${encodeURIComponent(
      modelName
    )}`
  );
  return response.data.map((item: string) => {
    return {
      id: `${item}`,
      name: item,
    };
  });
});

export const setSelectedArticleNumber = createAsyncThunk<
  {
    selectedArticleNumber: IArticleNumber;
    stationSpecs: IStationModel | null;
  },
  { articleNumber?: IArticleNumber; onSuccess?: (spec: IStationModel) => void }
>('articleNumbers/setSelectedArticleNumber', async ({ articleNumber, onSuccess }) => {
  if (!articleNumber?.id) {
    return {
      selectedArticleNumber: {} as IArticleNumber,
      stationSpecs: null,
    };
  }

  const response = await axiosRequest.get(`/stationSpecsByArticleNumber/${articleNumber.id}`);
  const specs = (response.data as IStationModel) ?? null;
  onSuccess && onSuccess(specs);

  return {
    selectedArticleNumber: { ...articleNumber },
    stationSpecs: specs,
  };
});

export const fetchStationsSpecsByArticleNumber = createAsyncThunk<IStationModel | null, { articleNumber: string }>(
  'articleNumbers/fetchStationsSpecsByArticleNumber',
  async ({ articleNumber }) => {
    const res = await getStationSpecsByArticleNumber(articleNumber);
    return res ?? null;
  }
);

export const articleNumbersSlice = createSlice({
  name: 'articleNumbers',
  initialState: { ...initialState },
  reducers: {
    resetArticleNumbersAction: (state) => {
      state.articleNumbers = initialState.articleNumbers;
      state.selectedArticleNumber = null;
      state.stationSpecs = null;
      state.status = initialState.status;
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder

      // fetchArticleNumbers
      .addCase(fetchArticleNumbers.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchArticleNumbers.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.articleNumbers = action.payload;
      })
      .addCase(fetchArticleNumbers.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // fetchArticleNumbersByManufacturerId
      .addCase(fetchArticleNumbersByModelId.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchArticleNumbersByModelId.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.articleNumbers = action.payload;
      })
      .addCase(fetchArticleNumbersByModelId.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // fetchArticleNumbersByManufacturerId
      .addCase(fetchArticleNumbersByManufacturerId.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchArticleNumbersByManufacturerId.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.articleNumbers = action.payload;
      })
      .addCase(fetchArticleNumbersByManufacturerId.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // setSelectedArticleNumber
      .addCase(setSelectedArticleNumber.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(setSelectedArticleNumber.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.selectedArticleNumber = action.payload.selectedArticleNumber;
        state.stationSpecs = action.payload.stationSpecs;
      })
      .addCase(setSelectedArticleNumber.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // fetchStationsSpecsByArticleNumber
      .addCase(fetchStationsSpecsByArticleNumber.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchStationsSpecsByArticleNumber.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.stationSpecs = action.payload;
      })
      .addCase(fetchStationsSpecsByArticleNumber.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetArticleNumbersAction } = articleNumbersSlice.actions;

export default articleNumbersSlice.reducer;
