import { createSlice } from '@reduxjs/toolkit';
import { STATUS_IDLE } from '../../../utils';

export const connectorSlice = createSlice({
  name: 'connector',
  initialState: {
    connector: {},
    status: STATUS_IDLE,
    error: null,
  },
});

export default connectorSlice.reducer;
