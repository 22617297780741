import { isRejected, createListenerMiddleware } from '@reduxjs/toolkit';
import { showErrorToast } from '../slices/ui/toastAlertSlice';

export const errorHandlingMiddleware = createListenerMiddleware();

errorHandlingMiddleware.startListening({
  matcher: isRejected,
  effect: async (action: any, listenerApi) => {
    // if aborted request, ignore message (with rejectWithValue or without)
    if (action.payload?.name === 'CanceledError' || action?.error?.name === 'CanceledError') {
      return;
    }
    let message = 'Something went wrong';

    // message from axios
    if (action.payload?.message?.length) {
      message = action.payload?.message;
    }

    // message from backend
    if (action.payload?.response?.data?.message) {
      message = action.payload.response.data.message;
    }

    listenerApi.dispatch(showErrorToast({ message }));
  },
});
