export const notificationTypes = [
  {
    label: 'notifications.type.all',
    value: 'all',
  },
  {
    label: 'notifications.type.unread',
    value: 'unread',
  },
  {
    label: 'notifications.type.warning',
    value: 'warning',
  },
  {
    label: 'notifications.type.error',
    value: 'error',
  },
];
