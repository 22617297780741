import React, { useMemo } from 'react';
import { Box, Typography, Divider, InputLabel } from '@mui/material';
import { ViewMoreText } from 'components/common/text/ViewMoreText';
import { getInputBoxStyle, getLabelStyle, getValueStyle } from 'components/utils';
import { defaultStyling } from 'types/styling';

// size = lg|md
export function ItemLabelValue({ label, value, size = 'lg', compressValue = false, styling = { ...defaultStyling } }) {
  const renderValue = value === '' || value === null || value === undefined ? '-' : value;

  const { isFlexRow, showBottomDivider, labelCustomStyle = {}, valueCustomStyle = {} } = styling;

  const valueStyle = useMemo(() => {
    return getValueStyle(isFlexRow, size, valueCustomStyle);
  }, [isFlexRow, size, valueCustomStyle]);

  return (
    <>
      <Box sx={getInputBoxStyle(isFlexRow, true)}>
        <InputLabel sx={getLabelStyle(isFlexRow, size, labelCustomStyle)}>{label}</InputLabel>

        {compressValue && <ViewMoreText text={renderValue} variant="subtitle4" sx={valueStyle} />}

        {!compressValue && (
          <Typography variant="subtitle4" component="div" sx={{ ...valueStyle, wordWrap: 'break-word' }}>
            {renderValue}
          </Typography>
        )}
      </Box>

      {showBottomDivider && <Divider />}
    </>
  );
}
