import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AsyncThunkConfig, IBillingSubscription, ReduxStatusEnum } from '../../../types';
import { axiosRequest } from '../../../api/axiosRequest';

interface IBillingState {
  billingSubscription: IBillingSubscription;
  billingSubscriptions: IBillingSubscription[];
  status: ReduxStatusEnum;
  error: unknown | string | null;
}

const initialState: IBillingState = {
  billingSubscription: {} as IBillingSubscription,
  billingSubscriptions: [],
  status: ReduxStatusEnum.IDLE,
  error: null,
};

export const fetchBillingSubscriptions = createAsyncThunk<
  IBillingSubscription[],
  { clientId: string | undefined },
  AsyncThunkConfig
>('billing/fetchBillingSubscriptions', async ({ clientId }, { rejectWithValue }) => {
  try {
    let query = '';
    if (clientId) {
      query = `?clientId=${clientId}`;
    }

    const response = await axiosRequest.get(`/billing/subscriptions${query}`);
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const fetchBillingSubscription = createAsyncThunk<
  IBillingSubscription,
  { clientId: string | undefined },
  AsyncThunkConfig
>('billing/fetchBillingSubscription', async ({ clientId }, { rejectWithValue }) => {
  try {
    let query = '';
    if (clientId) {
      query = `?clientId=${clientId}`;
    }

    const response = await axiosRequest.get(`/billing/subscriptions${query}`);
    return response.data?.length ? response.data[0] : ({} as IBillingSubscription);
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const billingSlice = createSlice({
  name: 'billing',
  initialState: { ...initialState },
  reducers: {
    resetBillingSubscription: (state) => {
      state.billingSubscription = {} as IBillingSubscription;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch all billingSubscriptions
      .addCase(fetchBillingSubscriptions.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchBillingSubscriptions.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.billingSubscriptions = action.payload;
      })
      .addCase(fetchBillingSubscriptions.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // fetch billingSubscription
      .addCase(fetchBillingSubscription.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchBillingSubscription.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.billingSubscription = action.payload;
      })
      .addCase(fetchBillingSubscription.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetBillingSubscription } = billingSlice.actions;

export default billingSlice.reducer;
