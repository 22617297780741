import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { ISmartChargingGroup } from '../../../types/SmartChargingGroup';
import { IApiGenericCallbackPayload, IApiResponseOk, AsyncThunkConfig } from '../../../types';

interface IInitialState {
  groups: ISmartChargingGroup[];
  group: ISmartChargingGroup;
  status: string;
  error: string | undefined;
}

export const fetchSmartChargingGroups = createAsyncThunk<ISmartChargingGroup[], AsyncThunkConfig>(
  'smartChargingGroups/fetch',
  async (thunkApi) => {
    try {
      const response = await axiosRequest.get(`/groups`);
      return response.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const getSmartChargingGroup = createAsyncThunk<ISmartChargingGroup, { id: string }, AsyncThunkConfig>(
  'smartChargingGroups/getItem',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/groups/${id}`);
      return { id, ...response.data };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createSmartChargingGroup = createAsyncThunk<
  IApiResponseOk,
  IApiGenericCallbackPayload<ISmartChargingGroup>,
  AsyncThunkConfig
>('smartChargingGroups/create', async ({ body, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
  try {
    const response = await axiosRequest.post('/groups', { ...body });

    onSuccessCallback && onSuccessCallback();

    return response.data;
  } catch (e) {
    onErrorCallback && onErrorCallback();

    return rejectWithValue(e);
  }
});

export const updateSmartChargingGroup = createAsyncThunk<
  IApiResponseOk,
  { id: string } & IApiGenericCallbackPayload<ISmartChargingGroup>,
  AsyncThunkConfig
>('smartChargingGroups/update', async ({ id, body, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
  try {
    const response = await axiosRequest.put(`/groups/${id}`, { ...body });

    onSuccessCallback && onSuccessCallback();

    return response.data;
  } catch (e) {
    onErrorCallback && onErrorCallback();

    return rejectWithValue(e);
  }
});

export const smartChargingGroupsSlice = createSlice({
  name: 'smartChargingGroups',
  initialState: {
    groups: [],
    group: {} as ISmartChargingGroup,
    status: STATUS_IDLE,
    error: undefined,
  } as IInitialState,
  reducers: {
    resetSmartChargingGroup: (state) => {
      state.group = {} as ISmartChargingGroup;
    },
  },
  extraReducers: (builder) => {
    builder

      // fetch smart charging groups
      .addCase(fetchSmartChargingGroups.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchSmartChargingGroups.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.groups = action.payload;
      })
      .addCase(fetchSmartChargingGroups.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // fetch smart charging group
      .addCase(getSmartChargingGroup.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(getSmartChargingGroup.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.group = { ...action.payload };
      })
      .addCase(getSmartChargingGroup.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // create smart charging group
      .addCase(createSmartChargingGroup.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(createSmartChargingGroup.fulfilled, (state) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(createSmartChargingGroup.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update smart charging group
      .addCase(updateSmartChargingGroup.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateSmartChargingGroup.fulfilled, (state) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(updateSmartChargingGroup.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetSmartChargingGroup } = smartChargingGroupsSlice.actions;

export default smartChargingGroupsSlice.reducer;
