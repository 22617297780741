import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchDriverChargingHistory = createAsyncThunk(
  'driver/fetchDriverChargingHistory',
  async ({ driverId }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/transactions/driver/${driverId}`);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const driverChargingHistorySlice = createSlice({
  name: 'driverChargingHistory',
  initialState: {
    driverChargingHistory: [],
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDriverChargingHistory.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchDriverChargingHistory.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.driverChargingHistory = action.payload;
      })
      .addCase(fetchDriverChargingHistory.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default driverChargingHistorySlice.reducer;
