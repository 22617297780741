export const LAYOUT = {
  SIDEBAR_WIDTH: 260,
};

export const gridSpacing = 2;

export const STATUS_IDLE = 'idle';
export const STATUS_LOADING = 'loading';
export const STATUS_FAILED = 'failed';
export const STATUS_SUCCESS = 'succeeded';

export const LAST_AUTH_PATH = 'LAST_AUTH_PATH';

export const DEBOUNCE_TIMEOUT_VALUE = 1000;
