import React from 'react';
import { Box, Grid } from '@mui/material';
import { DefaultBox } from 'components/common/DefaultBox';
import { theme } from 'themes/defaultTheme';

export function CenteredContainer({ children, flexGrow = false, disabled = false, gridSize = 4, sx = {} }) {
  return (
    <Box
      sx={[
        {
          p: theme.customSpacing.paddingAroundInside,
          [theme.breakpoints.down('sm')]: {
            p: 0,
          },
        },
        {
          ...(flexGrow && { display: 'flex', flexGrow: 1 }),
        },
        sx,
        !disabled ? {} : { opacity: 0.6, pointerEvents: 'none' },
      ]}
    >
      <Grid container justifyContent="center">
        <Grid item lg={gridSize} xs={12}>
          <DefaultBox>{children}</DefaultBox>
        </Grid>
      </Grid>
    </Box>
  );
}
