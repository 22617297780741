import { configureStore } from '@reduxjs/toolkit';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { errorHandlingMiddleware } from './middlewares/errorHandlingMiddleware';

import errorLogsReducer from './slices/analytics/errorLogsSlice';

import sidebarMenuReducer from './slices/sidebarMenuSlice';
import cardsReducer from './slices/cards/cardsSlice';
import stationsReducer from './slices/station/stationsSlice';
import stationLogsReducer from './slices/station/stationLogsSlice';
import stationChargingHistoryReducer from './slices/station/stationChargingHistorySlice';
import currentLocationReducer from './slices/currentLocationSlice';
import stationReducer from './slices/station/stationSlice';
import notificationReducer from './slices/notificationsSlice';
import stationFirmwaresReducer from './slices/stationFirmawares';
import locationsReducer from './slices/locationsSlice';
import registerStationStepReducer from './slices/station/registerStationStepSlice';
import userSettingsReducer from './slices/user/userSettingsSlice';
import companyDetailsReducer from './slices/company/companyDetailsSlice';
import importStationStepReducer from './slices/station/importStationStepSlice';
import registerCardStepReducer from './slices/cards/registerCardStepSlice';
import cardReducer from './slices/cards/cardSlice';
import importStationsReducer from './slices/stations/importStationsSlice';
import importCardsReducer from './slices/cards/importCardsSlice';
import registerCardsReducer from './slices/cards/registerCardsSlice';
import registerStationsReducer from './slices/stations/registerStationsSlice';
import importAndActivateStationsReducer from './slices/stations/importAndActivateStationsSlice';
import importAndActivateCardsReducer from './slices/cards/importAndActivateCardsSlice';
import userReducer from './slices/user/userSlice';
import cardGroupsReducer from './slices/cards/cardGroupsSlice';
import driverReducer from './slices/driver/driverSlice';
import driversTableReducer from './slices/driver/driversTableSlice';
import stationConfigurationReducer from './slices/station/stationConfigurationSlice';
import stationTerminalReducer from './slices/station/stationTerminalSlice';
import toastAlertReducer from './slices/ui/toastAlertSlice';
import clientsReducer from './slices/clients/clientsSlice';
import registerIndividualClientReducer from './slices/clients/registerIndividualClientStep';
import registerCompanyClientReducer from './slices/clients/registerCompanyClientStep';
import clientReducer from './slices/clients/clientSlice';
import cardChargingHistoryReducer from './slices/cards/cardChargingHistorySlice';
import clientsUsersReducer from './slices/clients/clientsUsersSlice';
import clientUserReducer from './slices/user/clientUserSlice';
import mainNumbersReducer from './slices/dashboards/main/mainNumbersSlice';
import stationsTransactionsReducer from './slices/dashboards/main/stationsTransactionsSlice';
import stationsStatusesReducer from './slices/dashboards/main/stationsStatusesSlice';
import stationsAnalyticsReducer from './slices/dashboards/main/stationsAnalyticsSlice';
import connectorsStatusesReducer from './slices/dashboards/main/connectorsStatusesSlice';
import connectorsReducer from './slices/connectors/connectorsSlices';
import connectorReducer from './slices/connectors/connectorSlice';
import modalReducer from './slices/ui/modalSlice';
import companyUsersReducer from './slices/company/companyUsersSlice';
import createModelStepReducer from './slices/models/createModelStepSlice';
import manufacturersReducer from './slices/models/manufacturesSlice';
import modelsReducer from './slices/models/modelsSlice';
import articleNumbersReducer from './slices/models/articleNumbersSlice';
import stationsModelsReducer from './slices/models/stationsModelsSlice';
import stationsLocationsReducer from './slices/locations/stationsLocationsSlice';
import windowLoaderReducer from './slices/ui/windowLoaderSlice';
import popupAlertReducer from './slices/ui/popupAlertSlice';
import stationsChargingProfileReducer from './slices/stations/chargingProfile/stationsChargingProfileSlice';
import stationsSmartChargingReducer from './slices/stations/smartCharging/stationsSmartChargingSlice';
import smartChargingDetailsReducer from './slices/stations/smartCharging/smartChargingDetailsSlice';
import chargingProfileReducer from './slices/chargingProfiles/chargingProfileSlice';
import stationDiagnosticsReducer from './slices/station/stationDiagnosticsSlice';
import stationsChargingSessionsReducer from './slices/station/stationsChargingSessionsSlice';
import smartChargingGroupsReducer from './slices/smartChargingGroups/smartChargingGroupsSlice';
import pricingWeekProfilesReducer from './slices/pricingProfiles/pricingWeekProfilesSlice';
import driverChargingHistoryReducer from './slices/driver/driverChargingHistorySlice';
import driverAuthorizationsReducer from './slices/driver/driverAuthorizationsSlice';
import pageTitleReducer from './slices/ui/pageTitleSlice';
import billingReceiveReducer from './slices/billing/billingReceiveSlice';
import billingPayReducer from './slices/billing/billingPaySlice';
import billingDriversReducer from './slices/billing/billingDriversSlice';
import billingReducer from './slices/billing/billingSlice';
import partnersReducer from './slices/partners/partnersSlice';
import partnerReducer from './slices/partners/partnerSlice';
import registerPartnerFormReducer from './slices/partners/registerPartnerSlice';
import partnersUsersReducer from './slices/partners/partnersUsersSlice';
import partnerUserReducer from './slices/user/partnerUserSlice';
import createStationStepReducer from './slices/station/createStationStepSlice';
import pricingProfileReducer from './slices/pricingProfiles/pricingProfileSlice';
import tariffsReducer from './slices/pricingProfiles/tariffsSlice';

const store = configureStore({
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat([errorHandlingMiddleware.middleware]);
  },
  reducer: {
    // analytics
    errorLogs: errorLogsReducer,

    // stations
    stations: stationsReducer,
    station: stationReducer,
    stationLogs: stationLogsReducer,
    stationChargingHistory: stationChargingHistoryReducer,
    stationsChargingSessions: stationsChargingSessionsReducer,
    stationFirmwares: stationFirmwaresReducer,
    registerStationStep: registerStationStepReducer,
    importStations: importStationsReducer,
    importStationStep: importStationStepReducer,
    registerStations: registerStationsReducer,
    importAndActivateStations: importAndActivateStationsReducer,
    stationConfiguration: stationConfigurationReducer,
    stationTerminal: stationTerminalReducer,
    stationsLocations: stationsLocationsReducer,
    stationDiagnostics: stationDiagnosticsReducer,
    createStationStep: createStationStepReducer,

    // station profiles
    stationsChargingProfile: stationsChargingProfileReducer,
    stationsSmartCharging: stationsSmartChargingReducer,
    smartChargingDetails: smartChargingDetailsReducer,
    chargingProfile: chargingProfileReducer,
    smartChargingGroups: smartChargingGroupsReducer,
    pricingWeekProfiles: pricingWeekProfilesReducer,
    pricingProfile: pricingProfileReducer,
    tariffs: tariffsReducer,

    // models
    manufacturers: manufacturersReducer,
    models: modelsReducer,
    articleNumbers: articleNumbersReducer,
    createModelStep: createModelStepReducer,
    stationsModels: stationsModelsReducer,

    // station connectors
    connectorsStatuses: connectorsStatusesReducer,
    connectors: connectorsReducer,
    connector: connectorReducer,

    // locations
    locations: locationsReducer,
    currentLocation: currentLocationReducer,

    // cards
    cards: cardsReducer,
    card: cardReducer,
    registerCardStep: registerCardStepReducer,
    importCards: importCardsReducer,
    registerCards: registerCardsReducer,
    cardGroups: cardGroupsReducer,
    cardChargingHistory: cardChargingHistoryReducer,
    importAndActivateCards: importAndActivateCardsReducer,

    // company
    companyDetails: companyDetailsReducer,
    companyUsers: companyUsersReducer,

    // user
    user: userReducer,
    userSettings: userSettingsReducer,
    notifications: notificationReducer,

    // driver
    driver: driverReducer,
    driversTable: driversTableReducer,
    driverChargingHistory: driverChargingHistoryReducer,
    driverAuthorizations: driverAuthorizationsReducer,

    // clients
    clients: clientsReducer,
    registerIndividualClient: registerIndividualClientReducer,
    registerCompanyClient: registerCompanyClientReducer,
    client: clientReducer,
    clientsUsers: clientsUsersReducer,
    clientUser: clientUserReducer,

    // partners
    partners: partnersReducer,
    partner: partnerReducer,
    registerPartner: registerPartnerFormReducer,
    partnersUsers: partnersUsersReducer,
    partnerUser: partnerUserReducer,

    // dashboard
    mainNumbers: mainNumbersReducer,
    stationsAnalytics: stationsAnalyticsReducer,
    stationsStatuses: stationsStatusesReducer,
    stationsTransactions: stationsTransactionsReducer,

    // UI
    windowLoader: windowLoaderReducer,
    popupAlert: popupAlertReducer,
    pageTitle: pageTitleReducer,
    modal: modalReducer,
    sidebarMenu: sidebarMenuReducer,
    toastAlert: toastAlertReducer,

    // billing
    billingReceive: billingReceiveReducer,
    billingPay: billingPayReducer,
    billingDrivers: billingDriversReducer,
    billing: billingReducer,
  },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
