import React, { useEffect, useState } from 'react';
import { Box, Drawer, Typography } from '@mui/material';
import { LAYOUT } from 'utils/constants';
import { getLogoFromDomain } from 'utils/image';
import { useTranslation } from 'react-i18next';
import packageJson from '../../../package.json';
import { SidebarMenu } from './SidebarMenu';

export function Sidebar({ openDrawer, hideDrawer, variant, isMobile }) {
  const { t } = useTranslation();

  const [logoObject, setLogoObject] = useState();

  useEffect(() => {
    getLogoFromDomain().then((l) => setLogoObject(l));
  }, []);

  return (
    <Drawer
      open={!!openDrawer}
      variant={variant}
      onClose={hideDrawer}
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          width: LAYOUT.SIDEBAR_WIDTH,
        },
      }}
      sx={{ zIndex: 1000 }}
    >
      <a href="/" style={{ textDecoration: 'none', textAlign: 'center' }}>
        <Box
          component="img"
          sx={{
            width: '55%',
            m: 0.7,
            // filter: 'invert(.5) brightness(4)',
          }}
          alt={logoObject?.alt ?? ''}
          src={logoObject?.src ?? ''}
        />
        {/* <Box sx={{ textAlign: 'center', color: "white" }}>
        <Typography variant="h3" component="span" sx={{ fontWeight: 300 }}>VO</Typography>
        <Typography variant="h3" component="span" sx={{ fontWeight: 600 }}>LT</Typography>
      </Box> */}
      </a>
      <SidebarMenu hideDrawer={hideDrawer} isMobile={isMobile} />
      <Box
        sx={{
          textAlign: 'center',
          marginTop: 'auto',
        }}
      >
        <Typography
          variant="h6"
          component="span"
          sx={{
            fontWeight: 300,
            fontSize: '14px',
            color: '#afabb9',
          }}
        >
          {t('global.label.version')} {packageJson.version}
        </Typography>
      </Box>
    </Drawer>
  );
}
