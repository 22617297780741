import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';
import { hideWindowLoader, showWindowLoader } from 'redux/slices/ui/windowLoaderSlice';
import { showErrorPopup, showSuccessPopup } from 'redux/slices/ui/popupAlertSlice';
import { hideModal } from 'redux/slices/ui/modalSlice';

export const updateConnector = createAsyncThunk(
  'connectors/updateConnector',
  async ({ id, key, value, onSuccessCallback }, { rejectWithValue }) => {
    try {
      onSuccessCallback && onSuccessCallback();
      return { id, key, value };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchConnectors = createAsyncThunk(
  'connectors/fetchConnectors',
  async ({ stationId }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/stations/${stationId}?`);
      return response.data.connectors;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const unlockConnector = createAsyncThunk(
  'connectors/unlockConnector',
  async ({ stationId, id, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.put(`/cs/${stationId}/unlockconnector/${id}`, {});
      onSuccessCallback && onSuccessCallback();

      return { id };
    } catch (e) {
      onErrorCallback && onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const lockConnector = createAsyncThunk(
  'connectors/lockConnector',
  async ({ stationId, id, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.put(`/cs/${stationId}/lockconnector/${id}`, {});
      onSuccessCallback && onSuccessCallback();

      return { id };
    } catch (e) {
      onErrorCallback && onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const startTransactionConnector = createAsyncThunk(
  'connectors/startTransaction',
  async ({ stationId, id, rfid, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.put(`/cs/${stationId}/starttransaction/${id}/${rfid}`, {});
      onSuccessCallback && onSuccessCallback();

      return { id };
    } catch (e) {
      onErrorCallback && onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const stopTransactionConnector = createAsyncThunk(
  'connectors/stopTransaction',
  async ({ stationId, connectorId, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.put(`/cs/${stationId}/stoptransaction/${connectorId}`, {});
      onSuccessCallback && onSuccessCallback();

      return {};
    } catch (e) {
      onErrorCallback && onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const reserveConnector = createAsyncThunk(
  'connectors/reserveConnector',
  async ({ stationId, rfid, id, seconds = 30, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.put(`/cs/${stationId}/reservations`, {
        cardId: rfid,
        connectorId: id,
        seconds: Number(seconds),
      });
      onSuccessCallback && onSuccessCallback();

      return { id };
    } catch (e) {
      onErrorCallback && onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const cancelReservationConnector = createAsyncThunk(
  'connectors/cancelReservationConnector',
  async ({ stationId, reservationId, onSuccessCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.delete(`/cs/${stationId}/reservations/${reservationId}`, {});
      onSuccessCallback && onSuccessCallback();

      return {};
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const resetConnector = createAsyncThunk(
  'connectors/resetConnector',
  async ({ stationId, type = 'softreset', onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.put(`/cs/${stationId}/reset/${type}`, {});
      onSuccessCallback && onSuccessCallback();

      return {};
    } catch (e) {
      onErrorCallback && onErrorCallback();

      return rejectWithValue(e);
    }
  }
);

export const changeAvailabilityConnector = createAsyncThunk(
  'connectors/changeAvailabilityConnector',
  async ({ stationId, id, operative = 'operative', onSuccessCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.put(`/cs/${stationId}/availability/${id}/${operative}`, {});
      onSuccessCallback && onSuccessCallback();

      return {};
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const setConnectorChargingProfile = createAsyncThunk(
  'connectors/setChargingProfile',
  async ({ stationId, connectorId, chargingProfileId, purpose, onSuccessCallback }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showWindowLoader());
      const response = await axiosRequest.put(
        `/cs/${stationId}/chargingprofiles/${connectorId}/${chargingProfileId}/${purpose}`,
        {}
      );
      onSuccessCallback && onSuccessCallback();
      dispatch(hideWindowLoader());

      return {};
    } catch (e) {
      dispatch(hideWindowLoader());
      return rejectWithValue(e);
    }
  }
);

//
export const startTransactionWithChargingProfile = createAsyncThunk(
  'connectors/startTransactionWithChargingProfile',
  async ({ stationId, connectorId, chargingProfileId, rfId, onSuccessCallback }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showWindowLoader());
      const response = await axiosRequest.put(
        `/cs/${stationId}/starttransactionwithprofile/${connectorId}/${rfId}/${chargingProfileId}`,
        {}
      );
      onSuccessCallback && onSuccessCallback();
      dispatch(hideWindowLoader());

      return {};
    } catch (e) {
      dispatch(hideWindowLoader());
      return rejectWithValue(e);
    }
  }
);

export const genericOCPPAction = createAsyncThunk(
  'connectors/genericOCPPAction',
  async ({ stationId, actionName, payload, onSuccessCallback, onErrorCallback }, { dispatch }) => {
    try {
      dispatch(showWindowLoader());

      const response = await axiosRequest.put(`/cs/${stationId}/actions/${actionName}`, { ...payload });
      dispatch(showSuccessPopup({ message: JSON.stringify(response.data, null, 4) }));
      onSuccessCallback && onSuccessCallback();
    } catch (e) {
      dispatch(showErrorPopup({ message: JSON.stringify(e.response.data, null, 4) }));
      onErrorCallback && onErrorCallback();
    }
    dispatch(hideModal({}));
    dispatch(hideWindowLoader());

    return {};
  }
);

export const connectorsSlice = createSlice({
  name: 'connectors',
  initialState: {
    connectors: [],
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    resetConnectors: {
      reducer: (state, action) => {
        state.connectors = [];
        state.status = STATUS_IDLE;
        state.error = null;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch connectors
      .addCase(fetchConnectors.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchConnectors.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.connectors = action.payload;
      })
      .addCase(fetchConnectors.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update connector
      .addCase(updateConnector.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateConnector.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.connectors = state.connectors.map((connector) => {
          if (connector.id === action.payload.id) {
            return { ...connector, [action.payload.key]: action.payload.value };
          }
          return connector;
        });
      })
      .addCase(updateConnector.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // unlockConnector
      .addCase(unlockConnector.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(unlockConnector.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(unlockConnector.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // lockConnector
      .addCase(lockConnector.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(lockConnector.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(lockConnector.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // startTransactionConnector
      .addCase(startTransactionConnector.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(startTransactionConnector.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(startTransactionConnector.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // stopTransactionConnector
      .addCase(stopTransactionConnector.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(stopTransactionConnector.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(stopTransactionConnector.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // reserve connector
      .addCase(reserveConnector.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(reserveConnector.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(reserveConnector.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // cancel reservation connector
      .addCase(cancelReservationConnector.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(cancelReservationConnector.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(cancelReservationConnector.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // resetConnector
      .addCase(resetConnector.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(resetConnector.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(resetConnector.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // change availability
      .addCase(changeAvailabilityConnector.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(changeAvailabilityConnector.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(changeAvailabilityConnector.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // generic OCPP action
      .addCase(genericOCPPAction.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(genericOCPPAction.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(genericOCPPAction.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // SET CHARGING PROFILE
      .addCase(setConnectorChargingProfile.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(setConnectorChargingProfile.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(setConnectorChargingProfile.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetConnectors } = connectorsSlice.actions;

export default connectorsSlice.reducer;
