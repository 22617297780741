import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AsyncThunkConfig,
  IApiGenericCallbackPayload,
  IPricingProfile,
  IPricingProfileForm,
  ReduxStatusEnum,
} from 'types';
import { axiosRequest } from 'api/axiosRequest';
import { CLIENT_ID_TENANT } from '../../../utils/models/client';
import { translate } from '../../../utils/i18n/i18n';

interface IState {
  list: IPricingProfile[];
  item: IPricingProfile | null;
  status: ReduxStatusEnum;
  error: unknown | string | null;
}

const initialState: IState = {
  list: [],
  item: null,
  status: ReduxStatusEnum.IDLE,
  error: null,
};

export const getPricingProfileAction = createAsyncThunk<IPricingProfile, { id: string }, AsyncThunkConfig>(
  'pricingProfile/getItem',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/pricingprofiles/${id}`);
      return { id, ...response.data };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getPricingProfilesAction = createAsyncThunk<
  IPricingProfile[],
  { clientId?: string | null },
  AsyncThunkConfig
>('pricingProfiles/getAll', async ({ clientId }, { rejectWithValue }) => {
  const freeItem = { id: null, name: translate('global.label.free') };
  try {
    let query = '';
    if (clientId?.length && clientId !== CLIENT_ID_TENANT) {
      query = `?clientId=${clientId}`;
    }
    const response = await axiosRequest.get(`/pricingprofiles${query}`);
    return [{ ...freeItem }, ...response.data];
  } catch (e) {
    return [{ ...freeItem }];
  }
});

export const createPricingProfileAction = createAsyncThunk<
  object,
  IApiGenericCallbackPayload<IPricingProfileForm>,
  AsyncThunkConfig
>('pricingProfiles/create', async ({ body, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
  try {
    await axiosRequest.post(`/pricingprofiles`, { ...body, clientName: undefined });
    onSuccessCallback && onSuccessCallback();
    return {};
  } catch (e) {
    onErrorCallback && onErrorCallback();
    return rejectWithValue(e);
  }
});

export const updatePricingProfileAction = createAsyncThunk<
  object,
  IApiGenericCallbackPayload<IPricingProfileForm>,
  AsyncThunkConfig
>('pricingProfiles/update', async ({ body, onSuccessCallback, onErrorCallback }, { rejectWithValue, dispatch }) => {
  try {
    await axiosRequest.put(`/pricingprofiles/${body?.id as string}`, { ...body });

    await dispatch(getPricingProfileAction({ id: body?.id as string }));
    onSuccessCallback && onSuccessCallback();

    return {};
  } catch (e) {
    onErrorCallback && onErrorCallback();
    return rejectWithValue(e);
  }
});

export const deletePricingProfileAction = createAsyncThunk<
  string,
  IApiGenericCallbackPayload<{ id: string }>,
  AsyncThunkConfig
>('pricingProfiles/delete', async ({ body, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
  try {
    await axiosRequest.delete(`/pricingprofiles/${body?.id as string}`);
    onSuccessCallback && onSuccessCallback();
    return body?.id as string;
  } catch (e) {
    onErrorCallback && onErrorCallback();
    return rejectWithValue(e);
  }
});

export const pricingProfileSlice = createSlice({
  name: 'pricingProfiles',
  initialState: { ...initialState },
  reducers: {
    resetPricingProfile: (state) => {
      state.item = initialState.item;
    },
  },
  extraReducers: (builder) => {
    builder
      // getPricingProfile
      .addCase(getPricingProfileAction.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(getPricingProfileAction.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.item = action.payload;
      })
      .addCase(getPricingProfileAction.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.payload;
      })

      // getPricingProfiles
      .addCase(getPricingProfilesAction.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(getPricingProfilesAction.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.list = action.payload;
      })
      .addCase(getPricingProfilesAction.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.payload;
      })

      // createPricingProfile
      .addCase(createPricingProfileAction.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(createPricingProfileAction.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
      })
      .addCase(createPricingProfileAction.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.payload;
      })

      // updatePricingProfile
      .addCase(updatePricingProfileAction.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(updatePricingProfileAction.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
      })
      .addCase(updatePricingProfileAction.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.payload;
      })

      // deletePricingProfile
      .addCase(deletePricingProfileAction.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(deletePricingProfileAction.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.list = state.list.filter((item) => item.id !== action.payload);
        state.item = initialState.item;
      })
      .addCase(deletePricingProfileAction.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.payload;
      });
  },
});

export const { resetPricingProfile } = pricingProfileSlice.actions;

export default pricingProfileSlice.reducer;
