import * as React from 'react';
import { theme } from 'themes/defaultTheme';
import { useTranslation } from 'react-i18next';

export function InputTextRequiredOrOptional({ required }) {
  const { t } = useTranslation();

  if (required) {
    return <span style={{ color: theme.palette.error.light }}>*</span>;
  }
  return <span style={{ fontSize: 12 }}>({t('input.optionalNote')})</span>;
}
