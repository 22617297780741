import { Fade, IconButton, Tooltip } from '@mui/material';
import { TfiInfoAlt } from 'react-icons/tfi';
import * as React from 'react';
import { theme } from 'themes/defaultTheme';

export function InfoTooltip({ tooltipComponent }) {
  return (
    <Tooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      title={tooltipComponent}
      sx={{
        '& .MuiTooltip-tooltip': {},
      }}
      placement="top"
      arrow
    >
      <IconButton sx={{ fontSize: '14px', p: 0, ml: 1 }}>
        <TfiInfoAlt style={{ cursor: 'pointer', color: theme.palette.primary.main }} />
      </IconButton>
    </Tooltip>
  );
}
