import React, { Fragment, useEffect, useState } from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { loadIcon } from 'utils/icons';
import { buildNavTree } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

const gray = '#bfbfbf';
const white = '#ffffff';
const subMenuSelectedStyle = { style: { color: white } };

export const SidebarMenu = ({ hideDrawer, isMobile }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const sidebarMenu = useSelector((state) => state.sidebarMenu.sidebarMenu);
  const [menu, setMenu] = useState(buildNavTree(sidebarMenu, location));

  useEffect(() => {
    setMenu(buildNavTree(sidebarMenu, location));
  }, [sidebarMenu, location]);

  const toggleMenuBranch = (clickedBranch) => {
    setMenu((prevState) =>
      prevState.map((group) => {
        const children = group.children.map((subGroup) => {
          if (subGroup.label === clickedBranch.label) {
            return { ...subGroup, open: !subGroup?.open }; // toggle the open/close
          }

          return subGroup;
        });

        return { ...group, children };
      })
    );
  };

  const handleRedirect = () => {
    isMobile && hideDrawer();
  };

  return menu?.map((menuGroup, groupIndex) => (
    <List
      key={groupIndex}
      component="nav"
      aria-labelledby={groupIndex}
      subheader={
        menuGroup.subHeader ? (
          <ListSubheader
            component="div"
            id={groupIndex}
            disableSticky
            sx={{
              color: 'rgba(255,255,255,0.66)',
              backgroundColor: 'transparent',
              m: 0,
              p: 0,
              lineHeight: '30px',
              fontSize: '13px',
              fontWeight: 'bold',
            }}
          >
            {menuGroup.key !== 'company-name' ? t(`menu.${menuGroup.key}`) : menuGroup.subHeader}
          </ListSubheader>
        ) : null
      }
    >
      {menuGroup?.children.map((menuSubGroup, subGroupIndex) =>
        menuSubGroup?.children ? (
          <Fragment key={subGroupIndex}>
            <ListItemButton onClick={() => toggleMenuBranch(menuSubGroup)}>
              {menuSubGroup?.icon ? (
                <ListItemIcon>{loadIcon(menuSubGroup?.icon, null, menuSubGroup.selected ? white : gray)}</ListItemIcon>
              ) : null}
              <ListItemText
                primaryTypographyProps={menuSubGroup.selected ? subMenuSelectedStyle : {}}
                sx={{
                  '& .MuiTypography-root': {
                    fontWeight: menuSubGroup.children.filter((menuItem) => menuItem.selected).length
                      ? 'bold!important'
                      : 'inherit',
                  },
                }}
                primary={t(`menu.${menuSubGroup.key}`)}
              />
              {menuSubGroup.open ? (
                <ExpandLess sx={{ color: gray }} /> // same on both flows
              ) : (
                <ExpandMore sx={{ color: gray }} />
              )}
            </ListItemButton>
            <Collapse in={menuSubGroup.open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {menuSubGroup.children.map((menuItem, itemIndex) => {
                  return (
                    <ListItemButton
                      key={itemIndex}
                      selected={menuItem.selected}
                      component={Link}
                      to={menuItem.href}
                      onClick={() => handleRedirect()}
                    >
                      <ListItemText sx={{ pl: 6 }} primary={t(`menu.${menuItem.key}`)} />
                    </ListItemButton>
                  );
                })}
              </List>
            </Collapse>
          </Fragment>
        ) : (
          <ListItemButton
            key={subGroupIndex}
            selected={menuSubGroup.selected}
            component={Link}
            to={menuSubGroup.href}
            onClick={() => handleRedirect()}
          >
            <ListItemIcon>
              {menuSubGroup?.icon ? (
                <ListItemIcon>
                  {loadIcon(menuSubGroup?.icon, null, location.pathname === menuSubGroup.href ? white : gray)}
                </ListItemIcon>
              ) : null}
            </ListItemIcon>
            <ListItemText primary={t(`menu.${menuSubGroup.key}`)} />
          </ListItemButton>
        )
      )}
    </List>
  ));
};
