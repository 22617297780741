import { createSlice } from '@reduxjs/toolkit';
import { locationInitialState } from 'utils/models/location';

const initialState = {
  currentLocation: locationInitialState,
};

export const currentLocationSlice = createSlice({
  name: 'currentLocation',
  initialState,
  reducers: {
    setCurrentLocation: {
      reducer: (state, action) => {
        state.currentLocation = { ...state.currentLocation, ...action.payload };
      },
    },
    resetCurrentLocation: {
      reducer: (state) => {
        state.currentLocation = initialState.currentLocation;
      },
    },
  },
});

export const { setCurrentLocation, resetCurrentLocation } = currentLocationSlice.actions;

export default currentLocationSlice.reducer;
