import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchDrivers = createAsyncThunk(
  'drivers/fetchDrivers',
  async ({ queryParameters }, { rejectWithValue }) => {
    try {
      const response = queryParameters
        ? await axiosRequest.get(`/drivers${queryParameters}`)
        : await axiosRequest.get('/drivers');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const clearDrivers = createAsyncThunk('drivers/clearDrivers', async (_, { rejectWithValue }) => {
  return [];
});

export const fetchDriversSlice = createSlice({
  name: 'drivers',
  initialState: {
    drivers: [],
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDrivers.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchDrivers.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.drivers = action.payload;
      })
      .addCase(fetchDrivers.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });

    builder
      .addCase(clearDrivers.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(clearDrivers.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.drivers = action.payload;
      })
      .addCase(clearDrivers.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default fetchDriversSlice.reducer;
