import { CustomTheme } from '../../types';

const Tabs = (theme: CustomTheme) => ({
  MuiTabs: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
});

export default Tabs;
