import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export function CustomDialog({
  open,
  onClose,
  title,
  children,
  closeButton = null,
  closeButtonHandle = null,
  okButton = null,
  okButtonHandle = null,
  fullScreen = false,
}) {
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      minWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ ...(fullScreen && { height: '100%' }) }}>{children}</DialogContent>
      {(closeButton || okButton) && (
        <DialogActions>
          {closeButton && <Button onClick={closeButtonHandle}>Close</Button>}
          {okButton && <Button onClick={okButtonHandle}>Apply</Button>}
        </DialogActions>
      )}
    </Dialog>
  );
}
