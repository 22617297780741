import { theme } from 'themes/defaultTheme';
import { ReactComponent as ConnectorType1 } from 'assets/connectorTypes/type1.svg';
import { ReactComponent as ConnectorType2 } from 'assets/connectorTypes/type2.svg';
import { ReactComponent as Chademo } from 'assets/connectorTypes/chademo.svg';
import { ReactComponent as Ccs } from 'assets/connectorTypes/ccs.svg';

export const CONNECTOR_STATUS_AVAILABLE = 'Available';
export const CONNECTOR_STATUS_PREPARING = 'Preparing';
export const CONNECTOR_STATUS_CHARGING = 'Charging';
export const CONNECTOR_STATUS_SUSPENDEDEV = 'SuspendedEV';
export const CONNECTOR_STATUS_SUSPENDEDEVSE = 'SuspendedEVSE';
export const CONNECTOR_STATUS_ERROR = 'Error';
export const CONNECTOR_STATUS_OFFLINE = 'Offline';
export const CONNECTOR_STATUS_UNAVAILABLE = 'Unavailable';
export const CONNECTOR_STATUS_FAULTED = 'Faulted';
export const CONNECTOR_STATUS_FINISHING = 'Finishing';
export const CONNECTOR_STATUS_RESERVED = 'Reserved';

export const POWER_TYPE_AC = 'AC';
export const POWER_TYPE_DC = 'DC';

export const CONNECTOR_TYPE_1 = 'Type 1';
export const CONNECTOR_TYPE_2 = 'Type 2';
export const CONNECTOR_TYPE_CCS = 'CCS';
export const CONNECTOR_TYPE_CHAdeMO = 'CHAdeMO';

export const CONNECTOR_FORMAT_SOCKET = 'Socket';
export const CONNECTOR_FORMAT_CABLE = 'Cable';

export const CONNECTOR_CONNECTION_TYPE_1_PHASE = '1-phase (230 V)';
export const CONNECTOR_CONNECTION_TYPE_3_PHASE = '3-phase (400 V)';
export const CONNECTOR_CONNECTION_TYPE_1_PHASE_LABEL = '1-phase (230 V)';
const CONNECTOR_CONNECTION_TYPE_3_PHASE_LABEL = '3-phase (400 V)';

export const connectorStatusColor = {
  [CONNECTOR_STATUS_AVAILABLE]: {
    name: CONNECTOR_STATUS_AVAILABLE,
    color: theme.palette.customColors.statuses.green,
  },
  [CONNECTOR_STATUS_PREPARING]: {
    name: CONNECTOR_STATUS_PREPARING,
    shortName: 'Occupied',
    color: theme.palette.customColors.statuses.purple,
    shortColor: theme.palette.customColors.statuses.yellow,
  },
  [CONNECTOR_STATUS_CHARGING]: {
    name: CONNECTOR_STATUS_CHARGING,
    color: theme.palette.customColors.statuses.blue,
  },
  [CONNECTOR_STATUS_SUSPENDEDEV]: {
    name: CONNECTOR_STATUS_SUSPENDEDEV,
    shortName: 'Occupied',
    color: theme.palette.customColors.statuses.yellow,
  },
  [CONNECTOR_STATUS_SUSPENDEDEVSE]: {
    name: CONNECTOR_STATUS_SUSPENDEDEVSE,
    shortName: 'Occupied',
    color: theme.palette.customColors.statuses.yellow,
  },
  [CONNECTOR_STATUS_ERROR]: {
    name: CONNECTOR_STATUS_ERROR,
    color: theme.palette.customColors.statuses.red,
  },
  [CONNECTOR_STATUS_OFFLINE]: {
    name: CONNECTOR_STATUS_OFFLINE,
    color: theme.palette.common.black,
  },
  [CONNECTOR_STATUS_UNAVAILABLE]: {
    name: CONNECTOR_STATUS_UNAVAILABLE,
    color: theme.palette.customColors.statuses.grey,
  },
  [CONNECTOR_STATUS_FAULTED]: {
    name: CONNECTOR_STATUS_FAULTED,
    shortName: 'Error',
    color: theme.palette.customColors.statuses.red,
  },
  [CONNECTOR_STATUS_FINISHING]: {
    name: CONNECTOR_STATUS_FINISHING,
    shortName: 'Occupied',
    color: theme.palette.customColors.statuses.fuxia,
    shortColor: theme.palette.customColors.statuses.yellow,
  },
  [CONNECTOR_STATUS_RESERVED]: {
    name: CONNECTOR_STATUS_RESERVED,
    shortName: 'Occupied',
    color: theme.palette.customColors.statuses.fuxia,
    shortColor: theme.palette.customColors.statuses.yellow,
  },
};

const iconType = {
  [CONNECTOR_TYPE_1]: {
    label: CONNECTOR_TYPE_1,
    icon: ConnectorType1,
  },
  [CONNECTOR_TYPE_2]: {
    label: CONNECTOR_TYPE_2,
    icon: ConnectorType2,
  },
  [CONNECTOR_TYPE_CHAdeMO]: {
    label: CONNECTOR_TYPE_CHAdeMO,
    icon: Chademo,
  },
  [CONNECTOR_TYPE_CCS]: {
    label: CONNECTOR_TYPE_CCS,
    icon: Ccs,
  },
};

export const getConnectorStatusColorObjectTranslated = () => {
  return Object.values(connectorStatusColor).reduce((acc, status) => {
    return {
      ...acc,
      [status.name]: {
        ...status,
        shortName: undefined,
        name: getConnectorStatusLabel(status.shortName || status.name),
      },
    };
  }, {});
};

export const getConnectorIcon = (connector) => {
  if (!connector?.connectorType) {
    return ConnectorType1;
  }

  return iconType[connector.connectorType]?.icon ?? ConnectorType1;
};

export const getConnectorColorByStatus = (status = CONNECTOR_STATUS_AVAILABLE) => {
  if (!status) {
    return connectorStatusColor[CONNECTOR_STATUS_AVAILABLE].color;
  }
  return connectorStatusColor[status]?.color ?? connectorStatusColor[CONNECTOR_STATUS_AVAILABLE].color;
};

export const connectorInitialState = {
  powerType: '',
  connectorType: '',
  format: '',
  voltage: '',
  connectionType: '',
};

export const connectorInitialStateNew = {
  powerType: '',
  connectorType: '',
  format: '',
  maxPower: '',
  maxVoltage: '',
  maxCurrent: '',
};

export const getConnectorInitialState = (isNew = false) => {
  const init = !isNew ? connectorInitialState : connectorInitialStateNew;

  return {
    key: Math.random().toString().substring(2, 6),
    ...init,
  };
};

export const getConnectorPowerTypes = () => {
  return [POWER_TYPE_AC, POWER_TYPE_DC];
};

export const getConnectorTypes = (powerType) => {
  if (powerType === POWER_TYPE_AC) {
    return [CONNECTOR_TYPE_1, CONNECTOR_TYPE_2];
  }

  if (powerType === POWER_TYPE_DC) {
    return [CONNECTOR_TYPE_CCS, CONNECTOR_TYPE_CHAdeMO];
  }

  return [CONNECTOR_TYPE_1, CONNECTOR_TYPE_2, CONNECTOR_TYPE_CCS, CONNECTOR_TYPE_CHAdeMO];
};

export const getConnectorFormats = (powerType) => {
  if (powerType === POWER_TYPE_DC) {
    return [CONNECTOR_FORMAT_CABLE];
  }

  return [CONNECTOR_FORMAT_CABLE, CONNECTOR_FORMAT_SOCKET];
};

export const getConnectorMaxVoltagePhases = () => {
  return [
    {
      id: CONNECTOR_CONNECTION_TYPE_1_PHASE,
      label: CONNECTOR_CONNECTION_TYPE_1_PHASE_LABEL,
    },
    {
      id: CONNECTOR_CONNECTION_TYPE_3_PHASE,
      label: CONNECTOR_CONNECTION_TYPE_3_PHASE_LABEL,
    },
  ];
};

/**
 * Calculate max power for connector in kW
 * @param maxVoltage
 * @param maxCurrent
 * @returns {string}
 */
export const calculateConnectorMaxPower = (maxVoltage, maxCurrent) => {
  if (!maxVoltage || !maxCurrent) {
    return '';
  }
  maxCurrent = parseFloat(maxCurrent);
  if (!maxCurrent) {
    return '';
  }

  if (maxVoltage === CONNECTOR_CONNECTION_TYPE_1_PHASE) {
    return (0.23 * maxCurrent).toFixed(2);
  }

  if (maxVoltage === CONNECTOR_CONNECTION_TYPE_3_PHASE) {
    return (0.4 * maxCurrent * Math.sqrt(3)).toFixed(2);
  }

  maxVoltage = parseFloat(maxVoltage);
  if (!maxVoltage) {
    return '';
  }

  return (maxVoltage * maxCurrent).toFixed(2);
};

export const areConnectorsEquals = (connector1, connector2) => {
  return getUniqueStringConnector(connector1) === getUniqueStringConnector(connector2);
};

export const getUniqueStringConnector = (connector) => {
  return `${connector.connectorType}-${connector.format}-${connector.maxPower}-${connector.powerType}-${connector.maxCurrent}-${connector.maxVoltage}`;
};

export const getUniqueConnectors = (connectors) => {
  if (!connectors || !connectors.length) {
    return [];
  }

  const uniqueConnectors = [connectors[0]];
  const configurations = [getUniqueStringConnector(connectors[0])];

  for (let i = 1; i < connectors.length; i++) {
    if (!configurations.includes(getUniqueStringConnector(connectors[i]))) {
      uniqueConnectors.push(connectors[i]);
      configurations.push(getUniqueStringConnector(connectors[i]));
    }
  }

  return uniqueConnectors;
};

export const getSameConnectorFromList = (connector, connectors) => {
  return connectors.find((c) => areConnectorsEquals(c, connector));
};

export const getSameConnectorsIndexesFromList = (connector, connectors) => {
  const sameIndexes = [];
  connectors.forEach((c, index) => {
    if (areConnectorsEquals(c, connector)) {
      sameIndexes.push(index);
    }
  });

  return sameIndexes;
};

export const showMaxPowerAsString = (maxPower) => {
  if (maxPower < 10) {
    return parseFloat(maxPower).toFixed(1);
  }

  return parseFloat(maxPower).toFixed(0);
};

export const showMaxVoltageAsString = (maxVoltage) => {
  if (!maxVoltage?.includes('V')) {
    return `${maxVoltage ?? 0} V`;
  }
  return `${maxVoltage.substring(maxVoltage.indexOf('(') + 1, maxVoltage.indexOf('V') + 1)}`;
};

export const canConnectorStartCharging = (connector, station) => {
  if (station?.details?.autoStart) {
    return false;
  }

  return [
    CONNECTOR_STATUS_PREPARING,
    CONNECTOR_STATUS_AVAILABLE,
    CONNECTOR_STATUS_RESERVED,
    CONNECTOR_STATUS_FINISHING,
  ].includes(connector?.status);
};

export const canConnectorStopCharging = (connector, station) => {
  if (station?.details?.autoStart) {
    return false;
  }

  return [CONNECTOR_STATUS_CHARGING, CONNECTOR_STATUS_SUSPENDEDEV, CONNECTOR_STATUS_SUSPENDEDEVSE].includes(
    connector.status
  );
};

export const canConnectorReserve = (connector, station) => {
  if (station?.details?.autoStart) {
    return false;
  }
  return [CONNECTOR_STATUS_AVAILABLE].includes(connector.status);
};

export const canConnectorCancelReservation = (connector, station) => {
  if (station?.details?.autoStart) {
    return false;
  }
  return [CONNECTOR_STATUS_RESERVED].includes(connector.status);
};

export const hasConnectorFreePrice = (connector) => {
  return !connector?.pricingProfile?.id;
};

export const getConnectorStatusLabel = (status) => {
  return `station.connectors.status.${status}`;
};
