import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { PAGE_NUMBER_INIT, PAGE_SIZE } from 'utils/pagination';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (
    {
      notificationType = 'all',
      page = PAGE_NUMBER_INIT,
      itemsPerPage = PAGE_SIZE,
      read = undefined,
      onSuccessCallback = null,
    },
    { rejectWithValue }
  ) => {
    try {
      let query = `?page=${page}&pageSize=${itemsPerPage}${
        notificationType === 'all' ? '' : `&status=${notificationType.toLowerCase()}`
      }`;
      if (read !== undefined) {
        query += `&read=${read}`;
      }

      const response = await axiosRequest.get(`/notifications${query}`);
      onSuccessCallback && onSuccessCallback();
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const countUnreadNotifications = createAsyncThunk(
  'notifications/countUnreadNotifications',
  async ({}, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get('/notifications/count');
      return { count: response.data.unread };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const resetNotifications = createAsyncThunk('notifications/resetNotifications', async () => {
  return [];
});

export const setAllNotificationsRead = createAsyncThunk(
  'notifications/setAllNotificationsRead',
  async ({ notificationType = 'all' }, { dispatch, rejectWithValue }) => {
    try {
      if (notificationType === 'all' || notificationType === 'unread') {
        await axiosRequest.put(`/notifications/all/markread`);
      } else {
        await axiosRequest.put(`/notifications/markread?status=${notificationType}`);
      }

      dispatch(countUnreadNotifications({}));

      return { notificationType };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const setNotificationRead = createAsyncThunk(
  'notifications/setNotificationRead',
  async ({ notificationId }, { rejectWithValue }) => {
    try {
      await axiosRequest.put(`/notifications/${notificationId}/markread`, {});

      return { id: notificationId };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
    totalUnread: 0,
    hasMore: true,
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        if (action.payload.length < PAGE_SIZE) {
          state.hasMore = false;
        }
        state.notifications = [...state.notifications, ...action.payload];
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(resetNotifications.fulfilled, (state) => {
        state.status = STATUS_SUCCESS;
        state.notifications = [];
        state.hasMore = true;
      })

      .addCase(setAllNotificationsRead.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(setAllNotificationsRead.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;

        const { notificationType } = action.payload;

        state.notifications = state.notifications.map((item) => {
          if (notificationType === 'all' || notificationType === 'unread') {
            return { ...item, read: true };
          }
          if (item.status === 'error' && notificationType === 'error') {
            return { ...item, read: true };
          }
          if (item.status === 'warning' && notificationType === 'warning') {
            return { ...item, read: true };
          }
          return item;
        });
      })
      .addCase(setAllNotificationsRead.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(setNotificationRead.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(setNotificationRead.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.notifications = state.notifications.map((item) => {
          if (item.id === action.payload.id) {
            state.totalUnread -= 1;
            return { ...item, read: true };
          }
          return item;
        });
      })
      .addCase(setNotificationRead.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(countUnreadNotifications.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(countUnreadNotifications.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.totalUnread = action.payload.count;
      })
      .addCase(countUnreadNotifications.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default notificationsSlice.reducer;
