import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { getStationStatusLabel, stationStatusesObject, STATUS_NOT_ACTIVATED } from '../../models/station';
import { allStations, cellStatus } from '../../DataGrid';
import { theme } from '../../../themes/defaultTheme';

export const useStationColumn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleActivateStation = (event: any, stationId: string, securityCode: string) => {
    event.stopPropagation();
    navigate(`/stations/activate/single?stationId=${stationId}&securityCode=${securityCode}`);
  };

  const stationStatusColumn = {
    accessorKey: 'status',
    header: t('global.header.status'),
    accessorFn: (row: any) => t(getStationStatusLabel(row?.status)),
    Cell: ({ row, cell }: any) => {
      if (cell.getValue() === t(getStationStatusLabel(STATUS_NOT_ACTIVATED))) {
        return (
          <Tooltip title={t('station.action.activate')}>
            <IconButton
              sx={{
                border: `1.5px solid ${theme.palette.primary.main}`,
                width: 28,
                height: 28,
              }}
              onClick={(event) => handleActivateStation(event, row?.original?.id, row?.original?.details?.securityCode)}
            >
              <AddIcon sx={{ fontSize: 18, color: 'primary.main' }} />
            </IconButton>
          </Tooltip>
        );
      }
      return cellStatus(allStations, row.original.status);
    },
    filterVariant: 'multi-select',
    filterSelectOptions: Object.keys(stationStatusesObject).map((key) => t(getStationStatusLabel(key))),
  };

  return {
    stationStatusColumn,
  };
};
