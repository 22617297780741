import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchSidebarMenu = createAsyncThunk('sidebarMenu/fetchSidebarMenu', async ({}, { rejectWithValue }) => {
  try {
    const response = await axiosRequest.get('/menu');
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const sidebarMenuSlice = createSlice({
  name: 'sidebarMenu',
  initialState: {
    sidebarMenu: [],
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    addSidebarMenu: {
      reducer: (state, action) => {
        state.sidebarMenu.push(action.payload);
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSidebarMenu.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchSidebarMenu.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.sidebarMenu = action.payload;
      })
      .addCase(fetchSidebarMenu.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { addSidebarMenu } = sidebarMenuSlice.actions;

export default sidebarMenuSlice.reducer;
