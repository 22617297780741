import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchCompanyUsers = createAsyncThunk(
  'company/fetchCompanyUsers',
  async ({ queryParameters }, { rejectWithValue }) => {
    try {
      const response = queryParameters
        ? await axiosRequest.get(`/users${queryParameters}`)
        : await axiosRequest.get('/users');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const clearCompanyUsers = createAsyncThunk('company/clearCompanyUsers', async (_, { rejectWithValue }) => {
  return [];
});

export const companyUsersSlice = createSlice({
  name: 'companyUsers',
  initialState: {
    companyUsers: [],
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyUsers.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchCompanyUsers.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.companyUsers = action.payload;
      })
      .addCase(fetchCompanyUsers.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(clearCompanyUsers.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(clearCompanyUsers.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.companyUsers = action.payload;
      })
      .addCase(clearCompanyUsers.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default companyUsersSlice.reducer;
