import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AsyncThunkConfig, IPricingProfile, ReduxStatusEnum } from 'types';
import { axiosRequest } from 'api/axiosRequest';

interface IState {
  list: IPricingProfile[];
  status: ReduxStatusEnum;
  error: unknown | string | null;
}

const initialState: IState = {
  list: [],
  status: ReduxStatusEnum.IDLE,
  error: null,
};

export const fetchTariffs = createAsyncThunk<IPricingProfile[], AsyncThunkConfig>(
  'tariffs/fetchTariffs',
  async ({}, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get('/pricingprofiles');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const tariffsSlice = createSlice({
  name: 'tariffs',
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get tariffs
      .addCase(fetchTariffs.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchTariffs.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.list = action.payload;
      })
      .addCase(fetchTariffs.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.payload;
      });
  },
});

export default tariffsSlice.reducer;
