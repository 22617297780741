import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchClientsUsers = createAsyncThunk(
  'clients/fetchClientsUsers',
  async ({ queryParameters }, { rejectWithValue }) => {
    try {
      const response = queryParameters
        ? await axiosRequest.get(`/clients/users${queryParameters}`)
        : await axiosRequest.get('/clients/users');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const clearClientsUsers = createAsyncThunk('clients/clearClientsUsers', async (_, { rejectWithValue }) => {
  return [];
});

export const clientsUsersSlice = createSlice({
  name: 'clientsUsers',
  initialState: {
    clientsUsers: [],
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientsUsers.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchClientsUsers.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.clientsUsers = action.payload;
      })
      .addCase(fetchClientsUsers.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(clearClientsUsers.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(clearClientsUsers.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.clientsUsers = action.payload;
      })
      .addCase(clearClientsUsers.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default clientsUsersSlice.reducer;
