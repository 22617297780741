import { CustomTheme } from '../../types';

const ListItemText = (theme: CustomTheme) => ({
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontSize: 14,
        color: theme.palette.common.white,
      },
    },
  },
});

export default ListItemText;
