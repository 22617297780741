const Autocomplete = () => ({
  MuiAutocomplete: {
    styleOverrides: {
      noOptions: {
        // theme.components.MuiOutlinedInput.styleOverrides.input.fontSize
        fontSize: '14px',
      },
    },
  },
});

export default Autocomplete;
