import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchBillingReceive = createAsyncThunk(
  'billingReceive/fetchBillingReceive',
  async ({}, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get('/billing/invoices/full/subscription/receive');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const billingReceiveSlice = createSlice({
  name: 'billingReceive',
  initialState: {
    billingReceive: [],
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    resetBillingReceive: (state) => {
      state.billingReceive = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBillingReceive.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchBillingReceive.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.billingReceive = action.payload;
      })
      .addCase(fetchBillingReceive.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetBillingReceive } = billingReceiveSlice.actions;

export default billingReceiveSlice.reducer;
