import { ICurrency, IRegisterPartnerFormPayload } from 'types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BILLING_PLAN_DEFAULT } from '../../../utils/models/billingPlan';

const initialState: IRegisterPartnerFormPayload = {
  details: {
    name: '',
    vatNumber: '',
    address: '',
    companyNumber: '',
  },
  user: {
    lastName: '',
    firstName: '',
    email: '',
    phone: '',
  },
  billing: {
    bank: '',
    holder: '',
    iban: '',
    swift: '',
    country: '',
  },
  billingPlan: BILLING_PLAN_DEFAULT,
  activeStep: 0,
};

const registerPartnerFormStepSlice = createSlice({
  name: 'registerPartnerFormStep',
  initialState: {
    data: { ...initialState },
  },
  reducers: {
    setPartnerRegisterPersonalDetails: (state, action: PayloadAction<Pick<IRegisterPartnerFormPayload, 'user'>>) => {
      state.data.user = {
        ...state.data.user,
        ...action.payload.user,
      };
    },

    setPartnerRegisterCompanyDetails: (state, action: PayloadAction<Pick<IRegisterPartnerFormPayload, 'details'>>) => {
      state.data.details = {
        ...state.data.details,
        ...action.payload.details,
      };
    },

    setPartnerRegisterBillingDetails: (state, action: PayloadAction<Pick<IRegisterPartnerFormPayload, 'billing'>>) => {
      state.data.billing = {
        ...state.data.billing,
        ...action.payload.billing,
      };
    },

    setPartnerRegisterBillingPlan: (state, action: PayloadAction<Pick<IRegisterPartnerFormPayload, 'billingPlan'>>) => {
      state.data.billingPlan = {
        ...state.data.billingPlan,
        ...action.payload.billingPlan,
      };
    },

    setPartnerRegisterCurrency: (state, action: PayloadAction<{ currency: ICurrency; vat: number }>) => {
      state.data.billingPlan = {
        subscription: {
          ...state.data.billingPlan.subscription,
          currency: action.payload.currency,
        },
        l1Support: {
          ...state.data.billingPlan.l1Support,
          currency: action.payload.currency,
        },
        vat: action.payload.vat,
      };
    },

    resetPartnerRegisterForm: (state) => {
      state.data = { ...initialState };
    },

    increasePartnerRegisterFormStep: (state) => {
      state.data.activeStep += 1;
    },

    decreasePartnerRegisterFormStep: (state) => {
      state.data.activeStep -= 1;
    },
  },
});

export const {
  setPartnerRegisterPersonalDetails,
  increasePartnerRegisterFormStep,
  resetPartnerRegisterForm,
  setPartnerRegisterBillingDetails,
  setPartnerRegisterBillingPlan,
  setPartnerRegisterCompanyDetails,
  decreasePartnerRegisterFormStep,
  setPartnerRegisterCurrency,
} = registerPartnerFormStepSlice.actions;

export default registerPartnerFormStepSlice.reducer;
