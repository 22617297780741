import React, { useCallback, useState } from 'react';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { breadcrumbsDictionary, LAYOUT } from 'utils';
import { theme } from 'themes/defaultTheme';
import { logout } from 'redux/slices/user/userSlice';
import { useTranslation } from 'react-i18next';
import { Notifications } from './Notifications';
import { BreadcrumbsComponent } from './BreadcrumbsComponent';
import { LocaleSelector } from '../../components/common/locale/LocaleSelector';

export function Navbar({ openDrawer, isMobile, showDrawer, hideDrawer }) {
  const { t } = useTranslation();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorNotificationsEl, setAnchorNotificationsEl] = useState(null);

  const totalUnreadNotifications = useSelector((state) => state.notifications.totalUnread);
  const user = useSelector((state) => state.user.user);
  const pageTitle = useSelector((state) => state.pageTitle);

  const { pathname } = useLocation();
  const { id, secondId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getPageTitle = useCallback(() => {
    if (pageTitle.secondTitle?.length) {
      return pageTitle.secondTitle;
    }

    if (pageTitle.title?.length) {
      return pageTitle.title;
    }

    const localTitle = breadcrumbsDictionary(id, secondId)[pathname];

    return localTitle ? t(localTitle) : '';
  }, [id, pathname, pageTitle.secondTitle, pageTitle.title, secondId, t]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onCloseNotifications = () => {
    setAnchorNotificationsEl(null);
  };

  const onOpenNotification = (event) => {
    setAnchorNotificationsEl(event.currentTarget);
  };

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        zIndex: 2,
        backgroundColor: 'white',
        width: openDrawer && !isMobile ? `calc(100% - ${LAYOUT.SIDEBAR_WIDTH}px)` : '100%',
        left: openDrawer && !isMobile ? `${LAYOUT.SIDEBAR_WIDTH}px` : 0,
      }}
    >
      <Toolbar>
        <Box>
          {openDrawer ? (
            <Tooltip title={t('navbar.hideSidebarMenu')}>
              <IconButton aria-label="toggle sidebarMenu" color="inherit" onClick={hideDrawer}>
                <MenuOpenIcon sx={{ color: '#000', fontSize: 26 }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={t('navbar.showSidebarMenu')}>
              <IconButton aria-label="toggle sidebarMenu" color="inherit" onClick={showDrawer}>
                <MenuIcon sx={{ color: '#000', fontSize: 26 }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Box
          sx={{
            ml: 2,
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          }}
        >
          <BreadcrumbsComponent />
          <Typography sx={{ color: '#000000', fontSize: 24 }}>{getPageTitle()}</Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />

        {/* LOCALE SELECTOR */}
        <LocaleSelector />

        {/* Notifications */}
        <Box>
          <Tooltip title={t('navbar.openNotifications')}>
            <IconButton
              aria-label={t('navbar.showNewNotifications')}
              color="inherit"
              onClick={(e) => {
                onOpenNotification(e);
              }}
              aria-describedby="notifications-popper"
            >
              <Badge badgeContent={totalUnreadNotifications} color="error">
                <NotificationsNoneIcon sx={{ color: 'primary.main', fontSize: 26 }} />
              </Badge>
            </IconButton>
          </Tooltip>

          <div>
            <Popover
              id="notifications-popper"
              open={Boolean(anchorNotificationsEl)}
              anchorEl={anchorNotificationsEl}
              onClose={onCloseNotifications}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              sx={{ height: 'calc(100% - 32px)', right: '32px' }}
            >
              <Notifications
                onSelectItem={() => {
                  onCloseNotifications();
                }}
              />
            </Popover>
          </div>
        </Box>

        {/* Settings */}
        <Box sx={{ flexGrow: 0, ml: 2 }}>
          <Tooltip title={t('navbar.openSettings')}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                sx={{
                  fontWeight: 600,
                  color: 'primary.main',
                  bgcolor: '#ebebeb',
                  fontSize: 14,
                }}
              >
                {user?.firstName?.substring(0, 1).toUpperCase()}
                {user?.lastName?.substring(0, 1).toUpperCase()}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              onClick={() => {
                handleCloseUserMenu();
                navigate('/my-profile');
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PersonOutlineIcon />
                <Typography textAlign="center" sx={{ ml: 1.5 }}>
                  {t('pages.title.myProfile')}
                </Typography>
              </Box>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleCloseUserMenu();
                dispatch(logout());
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LogoutIcon />
                <Typography textAlign="center" sx={{ ml: 1.5 }}>
                  {t('pages.title.logout')}
                </Typography>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            display: 'none',
          },
        }}
      >
        <Divider />
        <Box
          sx={{
            px: 2,
            py: 1.5,
          }}
        >
          <BreadcrumbsComponent />
        </Box>
        <Divider />
      </Box>
    </AppBar>
  );
}
