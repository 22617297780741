import * as React from 'react';
import { defaultStyling } from 'types/styling';
import { ItemLabelValue } from '../ItemLabelValue';
import { BasicSelect } from './BasicSelect';
import { LabelContainer } from './LabelContainer';

export function CustomSelect({
  showDisabledWithoutOpacity = false,
  placeholder = '',
  value,
  label,
  onChange,
  items,
  disabled = false,
  validation,
  valueDirty,
  setValueDirty,
  showCloseButton = false,
  extraProps = {},
  styling = { ...defaultStyling },
  sx = {},
  containerSx = {},
}) {
  if (disabled && showDisabledWithoutOpacity) {
    return <ItemLabelValue label={label} value={value} styling={styling} />;
  }

  return (
    <LabelContainer
      label={label}
      styling={styling}
      containerSx={containerSx}
      required={!!validation}
      showDisabledWithoutOpacity={showDisabledWithoutOpacity}
    >
      <BasicSelect
        value={value}
        placeholder={placeholder}
        valueDirty={valueDirty}
        setValueDirty={setValueDirty}
        validation={validation}
        disabled={disabled}
        onChange={onChange}
        items={items}
        extraProps={extraProps}
        showCloseButton={showCloseButton}
        sx={sx}
      />
    </LabelContainer>
  );
}
