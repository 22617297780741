import React from 'react';
import { Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { theme } from 'themes/defaultTheme';

export function TableContainer({ children }) {
  return (
    <Box
      sx={{
        // '& .css-ezws7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
        //   pr: '10px !important',
        // },

        // '& [role="combobox"]': {
        //   paddingRight: '10px !important',
        // },
        // '& .MuiPaper-root': {
        //   display: 'flex',
        //   flexDirection: 'column',
        //   flex: 1,
        // },
        display: 'flex',
        flex: 1,
        p: theme.customSpacing.paddingAroundInside,
        m: theme.customSpacing.marginAroundOutside,
        borderRadius: 1,
        backgroundColor: 'common.white',
        boxShadow: 27,
        // '& .highlightedRow': {
        //   backgroundColor: alpha(theme.palette.primary.main, 0.1),
        //   '&:hover': {
        //     bgcolor: alpha(theme.palette.primary.main, 0.15),
        //   },
        // },
      }}
    >
      {children}
    </Box>
  );
}
