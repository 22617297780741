import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchStationConfiguration = createAsyncThunk(
  'station/fetchStationConfiguration',
  async ({ id, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/cs/${id}/configuration`);
      onSuccessCallback && onSuccessCallback();
      return response.data;
    } catch (e) {
      onErrorCallback && onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const saveStationConfiguration = createAsyncThunk(
  'station/saveStationConfiguration',
  async ({ id, payloadValue, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.put(`/cs/${id}/configuration`, payloadValue, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      onSuccessCallback && onSuccessCallback();
      return response.data;
    } catch (e) {
      onErrorCallback && onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const clearStationConfiguration = createAsyncThunk(
  'station/clearStationConfiguration',
  async (_, { rejectWithValue }) => {
    return [];
  }
);

export const stationConfigurationSlice = createSlice({
  name: 'stationConfiguration',
  initialState: {
    stationConfiguration: [],
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder

      // fetchStationConfiguration
      .addCase(fetchStationConfiguration.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchStationConfiguration.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.stationConfiguration = action.payload;
      })
      .addCase(fetchStationConfiguration.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // saveStationConfiguration
      .addCase(saveStationConfiguration.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(saveStationConfiguration.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.stationConfiguration = action.payload;
      })
      .addCase(saveStationConfiguration.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // clearStationConfiguration
      .addCase(clearStationConfiguration.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(clearStationConfiguration.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.stationConfiguration = action.payload;
      })
      .addCase(clearStationConfiguration.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default stationConfigurationSlice.reducer;
