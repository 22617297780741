import { IBillingPlan, ICurrency } from '../../types';

export const BILLING_PLAN_DEFAULT = {
  subscription: {
    currency: ICurrency.RON,
    pricePerConnector: undefined,
  },
  l1Support: {
    currency: ICurrency.RON,
    pricePerConnector: undefined,
  },
} as IBillingPlan;

export const getPriceWithVat = (price: number | string | undefined, vat: number) => {
  const safeNumber = Number(price ?? 0);
  return (Math.round(100 * safeNumber + vat * safeNumber) / 100).toFixed(2);
};
