import { Box, MenuItem, Select, SxProps } from '@mui/material';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { ISelect, ISelectExtraProps, ISelectItem } from 'types';
import { theme } from 'themes/defaultTheme';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

type IProps = ISelect<string>;

export const BasicSelect = ({
  value,
  placeholder,
  valueDirty,
  setValueDirty,
  validation,
  disabled,
  items,
  sx,
  onChange,
  showCloseButton,
  extraProps,
}: IProps) => {
  const { t } = useTranslation();

  const error = validation ? valueDirty && !validation?.isValid : false;
  const helperText = valueDirty && !validation?.isValid && validation?.error;

  const localProps = extraProps ? { ...extraProps } : ({} as ISelectExtraProps);
  if (setValueDirty) {
    localProps.onBlur = () => setValueDirty(true);
  }

  const localSx = sx ? { ...sx } : ({} as SxProps);
  if (showCloseButton && value) {
    localProps.endAdornment = (
      <Box
        onClick={() => onChange({ target: { value: '' } } as SelectChangeEvent)}
        sx={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fontSize: theme.components?.MuiOutlinedInput?.styleOverrides?.input?.fontSize,
          cursor: 'pointer',
          position: 'absolute',
          right: 24,
          top: 8,
        }}
      >
        <CloseIcon />
      </Box>
    );

    if (localSx) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      localSx['& select'] = {
        paddingRight: '56px',
      };
    }
  }

  return (
    <>
      <Select
        value={value}
        displayEmpty={!!placeholder}
        renderValue={(selected: string) => {
          if (placeholder?.length && !selected?.length) {
            return <span style={{ opacity: 0.5 }}>{placeholder}</span>;
          }
          let valueToShow: ISelectItem['valueLabel'] = selected;
          items.forEach((item) => {
            if (item.id === selected) {
              if (item.valueLabel) {
                valueToShow = item.valueLabel;
              } else {
                valueToShow = item.label;
              }
            }
          });
          return valueToShow;
        }}
        onChange={onChange}
        disabled={disabled}
        error={error}
        size="small"
        margin="dense"
        sx={localSx}
        {...localProps}
      >
        {items.map((item) => {
          return (
            <MenuItem
              key={item.key ?? item.id}
              value={item.id}
              sx={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                fontSize: theme.components?.MuiOutlinedInput?.styleOverrides?.input?.fontSize,
              }}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Select>

      {error && helperText && (
        <p
          style={{
            color: theme.palette.error.main,
            marginTop: '4px',
            marginBottom: '0px',
            fontSize: '12px',
            fontWeight: '500',
          }}
        >
          {helperText ?? t('input.requiredText')}
        </p>
      )}
    </>
  );
};
