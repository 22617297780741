import { IConnector } from './Connector';
import { IApiGenericCallbackPayload } from './apiGeneric';

export interface IStation {
  id: string;
  registered: boolean;
  clientName: string | null;
  clientId: string | null;
  details: IStationDetails;
  wiFiSupported: boolean;
  gsmSupported: boolean;
  locationId: null;
  locationName: string;
  connectors: IConnector[];
  image: string;
  status: string;
  acceptedCards?: string[];
  partnerId?: string | null;
  partnerName?: string | null;
}

export interface IStationDetails {
  name: string;
  model: string;
  manufacturer: string;
  securityCode: string;
  serialNumber: string;
  articleNumber: string;
  maxCurrent: string;
  chargingType: ChargingTypeEnum;
  monthlyPayment?: boolean;
  amperage?: string; // old usage

  autoStart?: boolean | string;
}

export enum ChargingTypeEnum {
  public = 'public',
  private = 'private',
}

export type ApiUpdateStationSupportedCardsBody = string[] | undefined;

export interface ApiUpdateStationSupportedCardsPayload
  extends IApiGenericCallbackPayload<ApiUpdateStationSupportedCardsBody> {
  stationId: string;
}
