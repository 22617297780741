import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from '../../../api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from '../../../utils';

export const fetchStationLogs = createAsyncThunk(
  'station/fetchStationLogs',
  async ({ stationId, debugMode = false }, { rejectWithValue }) => {
    try {
      const response = debugMode
        ? await axiosRequest.get(`/stations/${stationId}/logs?debug=true`)
        : await axiosRequest.get(`/stations/${stationId}/logs`);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const stationLogsSlice = createSlice({
  name: 'stationLogs',
  initialState: {
    stationLogs: [],
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    addStationLogs: {
      reducer: (state, action) => {
        state.stationLogs.push(action.payload);
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStationLogs.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchStationLogs.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.stationLogs = action.payload;
      })
      .addCase(fetchStationLogs.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { addStationLogs } = stationLogsSlice.actions;

export default stationLogsSlice.reducer;
