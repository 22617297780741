import { formatDistance, format, setDefaultOptions } from 'date-fns';
import { getDateFnsLocale } from './i18n/helper';
import { getLocale } from './i18n/i18n';

setDefaultOptions({ locale: getDateFnsLocale(getLocale()) });

export const getDifferenceBetweenDatesAsString = (startTime: Date, endTime: Date) =>
  formatDistance(startTime, endTime, { addSuffix: true });

const DAYS_OF_THE_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const DAYS_FOR_API = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export const getDayOfTheByIndex = (index: number) => {
  return DAYS_OF_THE_WEEK[index];
};

export const getDayOfWeekLabel = (day: string) => {
  return `global.date.${day}`;
};

export const getDayIntervals = (ignoreFirst = true) => {
  const intervals = [] as { id: string; label: string }[];

  ['am', 'pm'].forEach((halfDay) => {
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].forEach((hour) => {
      let hourStr = '';
      if (halfDay === 'pm') {
        hourStr = `${hour + 12}`;
      } else {
        hourStr = `${hour < 10 ? `0${hour}` : hour}`;
      }
      const minutes = ['00', '30'];
      minutes.forEach((minute) => {
        intervals.push({
          id: `${hourStr}:${minute} ${halfDay}`,
          label: `${hourStr}:${minute} ${halfDay}`,
        });
      });
    });
  });
  return !ignoreFirst ? intervals : intervals.slice(1);
};

export const convertMinutesInDHHMM = (minutesValue: number) => {
  const days = Math.floor(minutesValue / (24 * 60));
  const hours = Math.floor((minutesValue - days * (24 * 60)) / 60);
  const minutes = minutesValue - days * 24 * 60 - hours * 60;
  return days > 0
    ? `${days}:${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
    : `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

export const convertSecondsInDHHMMSS = (secondsValue: number) => {
  const days = Math.floor(secondsValue / (24 * 60 * 60));
  const hours = Math.floor((secondsValue - days * (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((secondsValue - days * 24 * 60 * 60 - hours * 60 * 60) / 60);
  const seconds = secondsValue - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
  return days > 0
    ? `${days}:${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
        2,
        '0'
      )}`
    : `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export const formatDateToString = (date: Date) => {
  return format(date, 'dd MMM yyyy');
};
