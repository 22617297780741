import React, { useEffect, useRef } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';

import { useSelector } from 'react-redux';
import { theme } from 'themes/defaultTheme';
import { breadcrumbsDictionary } from 'utils';
import { useTranslation } from 'react-i18next';

function LinkRouter(props) {
  return (
    <Link
      style={{ color: theme.palette.primary.main, fontSize: '14px', display: 'block', width: 'max-content' }}
      {...props}
      component={RouterLink}
    />
  );
}

let currentIndex = 0;

export function BreadcrumbsComponent() {
  const { t } = useTranslation();

  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const { id, secondId } = useParams();
  const pageTitle = useSelector((state) => state.pageTitle);

  const breadCrumbsRef = useRef();

  useEffect(() => {
    if (!breadCrumbsRef.current || !pageTitle) {
      return;
    }
    breadCrumbsRef.current.scrollLeft = breadCrumbsRef.current.scrollWidth;
  }, [breadCrumbsRef?.current, pageTitle, pathnames]);

  useEffect(() => {
    currentIndex = 0;
  }, [pathnames]);

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      ref={breadCrumbsRef}
      sx={{
        overflowY: 'hidden',
        display: 'flex',
        '& .MuiBreadcrumbs-ol': {
          [theme.breakpoints.down('md')]: {
            flexWrap: 'nowrap',
          },
        },
        '&::-webkit-scrollbar': {
          height: '2px',
        },
      }}
      separator={
        <Typography color={theme.palette.grey[500]} fontSize="11px" pt={0.4}>
          /
        </Typography>
      }
    >
      <LinkRouter underline="hover" color="inherit" to="/">
        {t('pages.title.home')}
      </LinkRouter>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = decodeURI(`/${pathnames.slice(0, index + 1).join('/')}`);

        let breadcrumbsItem = breadcrumbsDictionary(id, secondId)[to];
        if (breadcrumbsItem) {
          breadcrumbsItem = t(breadcrumbsItem);
        }

        // second title
        if (!breadcrumbsItem && to.includes(secondId) && pageTitle?.secondTitle) {
          breadcrumbsItem = pageTitle.secondTitle;

          // first title
        } else if (!breadcrumbsItem) {
          breadcrumbsItem = pageTitle.breadcrumbParams?.length
            ? pageTitle.breadcrumbParams[currentIndex]
            : pageTitle.title;
          if (pageTitle.breadcrumbParams?.length > 1) {
            currentIndex++;
          }
        }

        return last ? (
          <Typography sx={{ lineHeight: '24px', width: 'max-content' }} fontSize="14px" color="text.primary" key={to}>
            {breadcrumbsItem}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="inherit" to={to} key={to}>
            {breadcrumbsItem}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
}
