import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_ID_TENANT } from 'utils/models/client';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { createLocationRequest } from 'api/requests/locationRequest';

export const fetchLocations = createAsyncThunk(
  'stations/fetchLocations',
  async ({ clientId = null }, { rejectWithValue }) => {
    try {
      let query = '';
      if (clientId?.length && clientId !== CLIENT_ID_TENANT) {
        query = `?clientId=${clientId}`;
      }

      const response = await axiosRequest.get(`/locations${query}`);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchLocationsStation = createAsyncThunk(
  'stations/fetchLocationsStation',
  async ({}, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get('/locations/stations');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createLocation = createAsyncThunk(
  'stations/createLocation',
  async ({ location, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      const response = await createLocationRequest(location);

      onSuccessCallback && onSuccessCallback(response);

      return response;
    } catch (e) {
      onErrorCallback && onErrorCallback();

      return rejectWithValue(e);
    }
  }
);

export const updateLocation = createAsyncThunk(
  'stations/updateLocation',
  async ({ locationId, location, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.put(`/locations/${locationId}`, {
        ...location,
      });
      onSuccessCallback && onSuccessCallback();

      return { ...location };
    } catch (e) {
      onErrorCallback && onErrorCallback();

      return rejectWithValue(e);
    }
  }
);

export const getLocation = createAsyncThunk('stations/getLocation', async ({ locationId }, { rejectWithValue }) => {
  try {
    const response = await axiosRequest.get(`/locations/${locationId}`);
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const locationsSlice = createSlice({
  name: 'locations',
  initialState: {
    locations: [],
    locationsStation: [],
    location: {},
    selectedLocationId: '',
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    addLocation: {
      reducer: (state, action) => {
        state.stations.push(action.payload);
      },
    },
    setSelectedLocation: {
      reducer: (state, action) => {
        state.selectedLocationId = action.payload;
      },
    },
    resetLocation: {
      reducer: (state, action) => {
        state.location = {};
      },
    },
    resetLocationsStations: {
      reducer: (state, action) => {
        state.locationsStation = [];
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.locations = action.payload;
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // create location
      .addCase(createLocation.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(createLocation.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(createLocation.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // updateLocation
      .addCase(updateLocation.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateLocation.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.location = { ...state.location, ...action.payload };
      })
      .addCase(updateLocation.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // getLocation
      .addCase(getLocation.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(getLocation.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.location = action.payload;
      })
      .addCase(getLocation.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // fetchLocationsStation
      .addCase(fetchLocationsStation.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchLocationsStation.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.locationsStation = action.payload;
      })
      .addCase(fetchLocationsStation.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { setSelectedLocation, resetLocation, resetLocationsStations } = locationsSlice.actions;

export default locationsSlice.reducer;
