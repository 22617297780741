import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchMainNumbers = createAsyncThunk('mainDashboard/fetchMainNumbers', async ({}, { rejectWithValue }) => {
  try {
    const response = await axiosRequest.get('/reports/totals');
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const mainNumbersSlice = createSlice({
  name: 'mainNumbers',
  initialState: {
    mainNumbers: [],
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMainNumbers.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchMainNumbers.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.mainNumbers = action.payload;
      })
      .addCase(fetchMainNumbers.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default mainNumbersSlice.reducer;
