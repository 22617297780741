import { Box, InputLabel, SxProps } from '@mui/material';
import * as React from 'react';
import { defaultStyling, IStyling } from '../../../types';
import { getInputBoxStyle, getLabelStyle } from '../../utils';
import { InputTextRequiredOrOptional } from './InputTextRequiredOrOptional';
import { InfoTooltip } from '../InfoTooltip';
import { theme } from '../../../themes/defaultTheme';

interface IProps {
  label: string;
  styling?: IStyling;
  labelSize?: 'lg' | 'md';
  disabled?: boolean;
  required?: boolean;
  infoTooltip?: string;
  containerSx?: SxProps;
  children: React.ReactNode;
}

export const LabelContainer = ({
  label,
  styling = { ...defaultStyling },
  labelSize = 'lg',
  disabled,
  required,
  infoTooltip,
  containerSx,
  children,
}: IProps) => {
  const { isFlexRow = false } = styling;

  return (
    <Box sx={getInputBoxStyle(isFlexRow, false, containerSx)}>
      {label?.length > 0 && (
        <InputLabel sx={getLabelStyle(isFlexRow, labelSize, styling?.labelCustomStyle ?? {})}>
          {label}
          {!disabled && required !== undefined && <InputTextRequiredOrOptional required={required} />}
          {infoTooltip && <InfoTooltip tooltipComponent={infoTooltip} />}
        </InputLabel>
      )}

      <Box
        sx={{
          ...getInputBoxStyle(isFlexRow),
          display: 'flex',
          gap: 1,
          position: 'relative',
          alignItems: 'flex-start',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
