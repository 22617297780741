import { alpha } from '@mui/material/styles';
import { CustomTheme } from '../../types';

const ListItemButton = (theme: CustomTheme) => ({
  MuiListItemButton: {
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(0.5),
        '&:hover': {
          backgroundColor: alpha(theme.palette.grey[400], 0.33),
        },
        '&.Mui-selected': {
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
          },
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },
});

export default ListItemButton;
