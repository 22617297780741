import { CustomTheme } from '../../types';

const SvgIcon = (theme: CustomTheme) => ({
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        fontSize: 20,
        color: theme.palette.customColors.icon,
      },
    },
  },
});

export default SvgIcon;
