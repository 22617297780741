import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchPartnersUsers = createAsyncThunk(
  'partners/fetchPartnersUsers',
  async ({ queryParameters }, { rejectWithValue }) => {
    try {
      const response = queryParameters
        ? await axiosRequest.get(`/partners/users${queryParameters}`)
        : await axiosRequest.get('/partners/users');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const clearPartnersUsers = createAsyncThunk('partners/clearPartnersUsers', async (_, { rejectWithValue }) => {
  return [];
});

export const partnersUsersSlice = createSlice({
  name: 'partnersUsers',
  initialState: {
    partnersUsers: [],
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartnersUsers.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchPartnersUsers.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.partnersUsers = action.payload;
      })
      .addCase(fetchPartnersUsers.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(clearPartnersUsers.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(clearPartnersUsers.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.partnersUsers = action.payload;
      })
      .addCase(clearPartnersUsers.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default partnersUsersSlice.reducer;
