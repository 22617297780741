import { getCurrentApiUrl } from './api';
import { getClientSubDomain } from './domain';

export const getSafeImagePath = (image: string): string => {
  if (image.startsWith('blob:')) {
    return image;
  }
  return image?.startsWith('http') ? image : `${getCurrentApiUrl(true) + image}`;
};

export const getLogoFromDomain = async (isBlack: false) => {
  const url = window.location.origin;
  const logoObj = { alt: 'ELVO', src: '' };

  if (
    !url.includes('localhost') &&
    !url.includes('demo.elvo.io') &&
    !url.includes('dev.elvo.io') &&
    !url.includes('my.elvo.io')
  ) {
    const domain = getClientSubDomain();
    const name = isBlack ? `${domain}-logo.svg` : `${domain}-logo-white.svg`;
    const response = await import(`assets/images/logos/${name}`); // change relative path to suit your needs
    logoObj.src = response.default;
    logoObj.alt = domain.toUpperCase();
  } else {
    const name = isBlack ? 'elvo-logo.svg' : 'elvo-logo-white.svg';
    const response = await import(`assets/images/logos/${name}`);
    logoObj.src = response.default;
  }

  return logoObj;
};
