import { theme } from 'themes/defaultTheme';

export const CARD_STATUS_ACTIVE = 'Active';
export const CARD_STATUS_NOT_ACTIVATED = 'Not activated';
export const CARD_STATUS_DEACTIVATED = 'Deactivated';
export const CARD_STATUS_EXPIRED = 'Expired';
export const CARD_NOT_YET_VALID = 'Not yet valid';

export const CARD_TYPES = [
  {
    id: 'card',
    label: 'card.type.rfid',
  },
  {
    id: 'token',
    label: 'card.type.rfid',
  },
  {
    id: 'APP_USER',
    label: 'card.type.mobile',
  },
  {
    id: 'mobile_public',
    label: 'card.type.mobilePublic',
  },
  {
    id: 'Mobile',
    label: 'card.type.mobile',
  },
  {
    id: 'RFID',
    label: 'card.type.rfid',
  },
];

export const getCardTypeLabel = (type) => {
  return CARD_TYPES.find((cardType) => cardType.id === type)?.label ?? '';
};

export const cardsStatusColor = {
  [CARD_STATUS_ACTIVE]: {
    name: CARD_STATUS_ACTIVE,
    color: theme.palette.customColors.statuses.green,
  },
  [CARD_STATUS_NOT_ACTIVATED]: {
    name: CARD_STATUS_NOT_ACTIVATED,
    color: theme.palette.customColors.statuses.grey,
  },
  [CARD_STATUS_DEACTIVATED]: {
    name: CARD_STATUS_DEACTIVATED,
    color: theme.palette.common.black,
  },
  [CARD_STATUS_EXPIRED]: {
    name: CARD_STATUS_EXPIRED,
    color: theme.palette.customColors.statuses.red,
  },
  [CARD_NOT_YET_VALID]: {
    name: CARD_NOT_YET_VALID,
    color: theme.palette.customColors.statuses.blue,
  },
};

export const getCardStatusColor = (status) => {
  return cardsStatusColor[status]?.color ?? theme.palette.common.black;
};

export const isCardNotActivated = (card) => {
  return card?.status === CARD_STATUS_NOT_ACTIVATED;
};

export const getCardStatusLabel = (status) => {
  if (!status) {
    return '';
  }
  return `card.status.${status}`;
};
