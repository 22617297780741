import React from 'react';

import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';

import { theme } from 'themes/defaultTheme';
import { Routes } from 'routes';
import { ToastAlert } from 'components/ToastAlert';
import { CustomModal } from 'components/common';
import WindowLoader from 'components/WindowLoader';
import { PopupAlert } from 'components/PopupAlert';
import store from './redux/store';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ToastAlert />
          <PopupAlert />
          <WindowLoader />
          <CustomModal />
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
