import { createSlice } from '@reduxjs/toolkit';

interface PageTitleSliceState {
  title: string;
  secondTitle?: string;
  breadcrumbParams: string[];
}

const initialState: PageTitleSliceState = {
  title: '',
  breadcrumbParams: [],
};

export const pageTitleSlice = createSlice({
  name: 'pageTitle',
  initialState,
  reducers: {
    setPageTitle: (state, action) => {
      state.title = action.payload.title;
      if (action.payload.breadcrumbParams) {
        state.breadcrumbParams = action.payload.breadcrumbParams;
      }
      if (action.payload.secondTitle) {
        state.secondTitle = action.payload.secondTitle;
      }
    },
    setSecondTitle: (state, action) => {
      state.secondTitle = action.payload.secondTitle;
    },
    setPageBreadcrumbParams: (state, action) => {
      state.breadcrumbParams = action.payload.breadcrumbParams;
    },
    resetPageTitle: (state) => {
      state.title = initialState.title;
      state.secondTitle = initialState.secondTitle;
      state.breadcrumbParams = initialState.breadcrumbParams;
    },
    resetSecondTitle: (state) => {
      state.secondTitle = initialState.secondTitle;
    },
  },
});

export const { resetPageTitle, resetSecondTitle, setPageTitle, setSecondTitle, setPageBreadcrumbParams } =
  pageTitleSlice.actions;

export default pageTitleSlice.reducer;
