import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';
import { CARD_STATUS_ACTIVE, CARD_STATUS_DEACTIVATED } from 'utils/models/card';

export const fetchCard = createAsyncThunk('station/fetchCard', async (cardId, { rejectWithValue }) => {
  try {
    const response = await axiosRequest.get(`/cards/${cardId}`);
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const importSingleCard = createAsyncThunk(
  'cards/importCards',
  async ({ clientId, isUpdate, rfId, contractId, type, onSuccessCallback }, thunkApi) => {
    try {
      let response;
      if (!isUpdate) {
        response = await axiosRequest.post(`/import/card`, {
          clientId: clientId ?? undefined,
          rfId,
          contractId,
          type,
        });
      } else {
        response = await axiosRequest.put(`/import/card`, {
          rfId,
          clientId: clientId ?? undefined,
          contractId,
          type,
        });
      }

      onSuccessCallback && onSuccessCallback();

      if (isUpdate) {
        return { data: response.data?.message };
      }
      return { data: {} };
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const registerCard = createAsyncThunk(
  'card/registerCard',
  async ({ contractId, details, billing, onSuccessCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.post(`/register/card/${contractId}`, {
        details,
        billing,
      });
      onSuccessCallback && onSuccessCallback();
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createCard = createAsyncThunk(
  'card/createCard',
  async ({ clientId, rfId, contractId, type, details, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.post(`/import/card`, {
        clientId: clientId ?? undefined,
        rfId,
        contractId,
        type,
      });

      const response = await axiosRequest.post(`/register/card/${contractId}`, {
        details,
      });
      onSuccessCallback && onSuccessCallback();
      return response.data;
    } catch (e) {
      onErrorCallback && onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const updateCard = createAsyncThunk(
  'card/updateCard',
  async ({ cardId, details, onSuccessCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.put(`/cards/${cardId}`, { details });
      const result = {
        details,
        clientId: details.clientId,
        clientName: details.clientName,
        groupId: details.groupId,
        type: details.type,
      };
      onSuccessCallback && onSuccessCallback();
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const activateCard = createAsyncThunk(
  'card/activateCard',
  async ({ cardId, isActive, onSuccessCallback }, { rejectWithValue }) => {
    try {
      const status = isActive ? 'active' : 'inactive';

      const response = await axiosRequest.put(`/cards/${cardId}/status/${status}`, {});
      onSuccessCallback && onSuccessCallback();

      return { isActive };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteCardAction = createAsyncThunk(
  'card/deleteCardAction',
  async ({ cardId, onSuccessCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.delete(`/cards/${cardId}`);
      onSuccessCallback && onSuccessCallback();

      return {};
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const setCardSeeAllStationsAction = createAsyncThunk(
  'card/setCardSeeAllStationsAction',
  async ({ cardId, seeAllStations, onSuccessCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.put(`/cards/${cardId}/seeAllStations/${seeAllStations}`);
      onSuccessCallback && onSuccessCallback();

      return { seeAllStations };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const initialState = {
  card: {},
  status: STATUS_IDLE,
  error: null,
};
export const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    resetCard: {
      reducer: (state, action) => {
        state.card = initialState.card;
        state.status = initialState.status;
        state.error = initialState.error;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(importSingleCard.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(importSingleCard.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.card = action.payload.data;
      })
      .addCase(importSingleCard.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(registerCard.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(registerCard.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.card = action.payload;
      })
      .addCase(registerCard.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(createCard.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(createCard.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.card = action.payload;
      })
      .addCase(createCard.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(fetchCard.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchCard.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.card = action.payload;
      })
      .addCase(fetchCard.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // updateCard
      .addCase(updateCard.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateCard.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.card = {
          ...state.card,
          ...action.payload,
          details: {
            ...state.card.details,
            ...action.payload.details,
          },
        };
      })
      .addCase(updateCard.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // activateCard
      .addCase(activateCard.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(activateCard.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.card.active = action.payload.isActive;
        state.card.status = action.payload.isActive ? CARD_STATUS_ACTIVE : CARD_STATUS_DEACTIVATED;
      })
      .addCase(activateCard.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })

      // deleteCardAction
      .addCase(deleteCardAction.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(deleteCardAction.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.card = {};
      })
      .addCase(deleteCardAction.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })

      // setCardSeeAllStationsAction
      .addCase(setCardSeeAllStationsAction.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(setCardSeeAllStationsAction.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.card.details = {
          ...state.card.details,
          seeAllStations: action.payload.seeAllStations,
        };
      })
      .addCase(setCardSeeAllStationsAction.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      });
  },
});

export const { resetCard } = cardSlice.actions;

export default cardSlice.reducer;
