import { createSlice } from '@reduxjs/toolkit';
import { locationInitialState } from 'utils/models/location';

const initialState = {
  cardDetails: {
    contractId: '',
    name: '',
    clientId: '',
    groupId: '',
    client: {
      id: '',
      label: '',
    },
  },
  billingInformation: {
    companyName: '',
    email: '',
    phone: '',
    vatNumber: '',
    location: locationInitialState,
  },
  billingPlan: {},
  activeStep: 0,
};

export const registerCardStepSlice = createSlice({
  name: 'registerCardStep',
  initialState: {
    data: initialState,
  },
  reducers: {
    setCardDetails: {
      reducer: (state, action) => {
        state.data.cardDetails = {
          ...state.data.cardDetails,
          ...action.payload,
        };
      },
    },
    setBillingInformationCardRegisterStep: {
      reducer: (state, action) => {
        state.data.billingInformation = action.payload;
      },
    },
    resetRegisterCardStep: {
      reducer: (state) => {
        state.data = initialState;
      },
    },
    increaseRegisterCardStep: {
      reducer: (state, action) => {
        const stepSize = state.data.activeStep === 1 ? 2 : 1;

        state.data.activeStep += stepSize;
      },
    },
    decreaseRegisterCardStep: {
      reducer: (state) => {
        const stepSize = state.data.activeStep === 3 ? 2 : 1;
        state.data.activeStep -= stepSize;
      },
    },
  },
});

export const {
  setCardDetails,
  setBillingInformationCardRegisterStep,
  resetRegisterCardStep,
  increaseRegisterCardStep,
  decreaseRegisterCardStep,
} = registerCardStepSlice.actions;

export default registerCardStepSlice.reducer;
