import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchCardGroups = createAsyncThunk('cards/fetchCardGroups', async ({}, { rejectWithValue }) => {
  try {
    const response = await axiosRequest.get('/cards/groups');
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const cardGroupsSlice = createSlice({
  name: 'cards',
  initialState: {
    groups: [],
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    addCards: {
      reducer: (state, action) => {
        state.cards.push(action.payload);
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCardGroups.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchCardGroups.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.groups = action.payload;
      })
      .addCase(fetchCardGroups.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default cardGroupsSlice.reducer;
