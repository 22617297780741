import { CustomTheme } from '../../types';

const Drawer = (theme: CustomTheme) => ({
  MuiDrawer: {
    styleOverrides: {
      paper: {
        padding: theme.spacing(1.5),
        backgroundColor: theme.palette.customColors.backgroundDrawer,
      },
    },
  },
});

export default Drawer;
