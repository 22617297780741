import React, { lazy } from 'react';
import Loadable from 'components/Loadable';

const LogoutPage = Loadable(lazy(() => import('pages/Auth/Logout/LogoutPage')));

const LogoutRoute = {
  path: 'logout',
  element: <LogoutPage />,
};

export default LogoutRoute;
