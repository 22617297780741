import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { stringCompare } from '../../helpers';
import { cellStatus, chargingHistoryStatuses } from '../../DataGrid';

export const useChargingHistoryColumn = () => {
  const { t } = useTranslation();
  const allUniqueChargingHistoryStatuses = useMemo<string[]>(
    () => [...new Set(Object.values(chargingHistoryStatuses).map((item) => t(item.name)))].sort(stringCompare),
    []
  );

  const statusColumn = {
    accessorKey: 'status',
    accessorFn: (row: any) => t(`chargingHistory.status.${row?.status}`),
    header: t('global.header.status'),
    Cell: ({ row, cell }: any) => cellStatus(chargingHistoryStatuses, row.original.status),
    filterVariant: 'multi-select',
    filterSelectOptions: allUniqueChargingHistoryStatuses,
  };

  return {
    statusColumn,
  };
};
