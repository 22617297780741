import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchStationFirmwares = createAsyncThunk(
  'stationFirmwares/fetchStationFirmwares',
  async ({}, { rejectWithValue }) => {
    return [];
  }
);

export const stationFirmwaresSlice = createSlice({
  name: 'stations',
  initialState: {
    firmwares: [],
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStationFirmwares.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchStationFirmwares.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.firmwares = action.payload;
      })
      .addCase(fetchStationFirmwares.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default stationFirmwaresSlice.reducer;
