import { ILatLng, IReverseGeocoderResult } from '../types/reverseGeocode';

export const getAddressFromPlacesDetails = (
  googleDetails: any,
  forceLatLngValues: ILatLng | null = null
): IReverseGeocoderResult | null => {
  let country = '';
  let city = '';
  let streetName = '';
  let streetNumber = '';
  let zipCode = '';
  let latitude = 0;
  let longitude = 0;

  if (Object.keys(googleDetails).length === 0) {
    return null;
  }
  if (googleDetails?.address_components) {
    let check = false;
    for (let i = 0; i < googleDetails?.address_components.length; i++) {
      const item = googleDetails?.address_components[i];

      if (item?.types.includes('country')) {
        country = item?.long_name;
        check = true;
        continue;
      }
      if (item?.types.includes('locality')) {
        city = item?.long_name;
        check = true;
        continue;
      }

      if (item?.types.includes('route')) {
        check = true;
        streetName = item?.long_name;
        continue;
      }

      if (item?.types.includes('street_number')) {
        check = true;
        streetNumber = item?.long_name;
        continue;
      }

      if (item?.types.includes('postal_code')) {
        check = true;
        zipCode = item?.short_name;
      }
    }

    // get postal_code

    if (!check) {
      return null;
    }
  }

  if (forceLatLngValues) {
    latitude = forceLatLngValues?.lat;
    longitude = forceLatLngValues?.lng;
  } else {
    const lat = googleDetails?.geometry?.location?.lat ?? '';
    const long = googleDetails?.geometry?.location?.lng ?? '';
    latitude = typeof lat === 'function' ? lat() : lat;
    longitude = typeof long === 'function' ? long() : long;
  }

  return {
    city,
    streetName,
    streetNumber,
    country,
    zipCode,
    latitude: String(latitude),
    longitude: String(longitude),
  };
};

export const areServicesAvailable = (autocompleteService: any, geocoderService: any, window: Window | any): boolean => {
  if (!autocompleteService?.current && window?.google) {
    autocompleteService.current = new window.google.maps.places.AutocompleteService();
  }
  if (!autocompleteService?.current) {
    return false;
  }

  if (!geocoderService?.current && window.google) {
    geocoderService.current = new window.google.maps.Geocoder();
  }
  return !!geocoderService?.current;
};
