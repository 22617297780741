import React, { Suspense } from 'react';

// project import
import ProgressLineLoader from './ProgressLineLoader';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<ProgressLineLoader />}>
    <Component {...props} />
  </Suspense>
);

export default Loadable;
