import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchStationsLocations = createAsyncThunk(
  'stationsLocations/fetchStationsLocations',
  async ({}, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get('/locations');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteStationLocation = createAsyncThunk(
  'stationsLocations/deleteStationLocation',
  async ({ stationLocationId, onSuccessCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.delete(`/locations/${stationLocationId}`);
      onSuccessCallback && onSuccessCallback();
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const stationsLocationsSlice = createSlice({
  name: 'stationsLocations',
  initialState: {
    stationsLocations: [],
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      // fetch stations locations
      .addCase(fetchStationsLocations.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchStationsLocations.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.stationsLocations = action.payload;
      })
      .addCase(fetchStationsLocations.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default stationsLocationsSlice.reducer;
