import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import dashboardClient from 'utils/dashboards/dashboardClient.json';
import dashboardPartner from 'utils/dashboards/dashboardPartner.json';
import dashboardEnterprise from 'utils/dashboards/dashboardEnterprise.json';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchUserSettings = createAsyncThunk(
  'userSettings/fetchUserSettings',
  async ({ userId, userLevel }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/users/${userId}/settings`);
      let defaultDashboard = { f: 3 };
      if (userLevel === 1) defaultDashboard = dashboardClient;
      if (userLevel === 2) defaultDashboard = dashboardPartner;
      if (userLevel === 3) defaultDashboard = dashboardEnterprise;
      return { ...defaultDashboard, ...response.data };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateUserSettings = createAsyncThunk(
  'userSettings/updateUserSettings',
  async ({ userId, bodyObj }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.put(`/users/${userId}/settings`, bodyObj);
      // const response1 = await axiosRequest.get(`/users/${userId}/settings`);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const patchUserSettings = createAsyncThunk(
  'userSettings/updateUserSettings',
  async ({ userId, bodyObj }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.patch(`/users/${userId}/settings`, bodyObj);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState: {
    userSettings: null,
    status: STATUS_IDLE,
    error: null,
  },
  reducer: {},
  extraReducers: (builder) => {
    builder

      // fetchUserSettings
      .addCase(fetchUserSettings.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchUserSettings.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.userSettings = { ...action.payload };
      })
      .addCase(fetchUserSettings.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // updateUserSettings
      .addCase(updateUserSettings.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateUserSettings.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.userSettings = action.payload;
      })
      .addCase(updateUserSettings.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default userSettingsSlice.reducer;
