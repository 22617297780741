import React, { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoginRoutes from 'routes/LoginRoutes';
import MainRoutes from 'routes/MainRoutes';
import LogoutRoute from 'routes/LogoutRoute';
import { loadFromLocalStorage } from 'utils';
import { reauth } from 'redux/slices/user/userSlice';
import { fetchSidebarMenu } from 'redux/slices/sidebarMenuSlice';
import { countUnreadNotifications } from 'redux/slices/notificationsSlice';
import ProgressLineLoader from 'components/ProgressLineLoader';

export const Routes = () => {
  const localUser = loadFromLocalStorage('user');
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);
  const user = useSelector((state) => state.user);
  const routes = useRoutes([LoginRoutes, MainRoutes, LogoutRoute]);

  useEffect(() => {
    if (user?.token) {
      // do we need to make these dispatches? since we menu data and notifications were already requested on login?
      // is it for requesting fresh data?
      dispatch(fetchSidebarMenu({}));
      dispatch(countUnreadNotifications({}));
      setIsReady(true);
      return;
    }

    if (localUser?.token) {
      if (!user?.token) {
        dispatch(reauth({ ...localUser }));
      }
    } else {
      setIsReady(true);
    }
  }, [user?.token]);

  return isReady ? routes : <ProgressLineLoader />;
};
