import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchDriverAuthorizations = createAsyncThunk(
  'driver/fetchDriverAuthorizations',
  async ({ driverId }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/cards/by-driver/${driverId}`);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const driverAuthorizationsSlice = createSlice({
  name: 'driverAuthorizations',
  initialState: {
    driverAuthorizations: [],
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDriverAuthorizations.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchDriverAuthorizations.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.driverAuthorizations = action.payload;
      })
      .addCase(fetchDriverAuthorizations.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default driverAuthorizationsSlice.reducer;
