import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchPartners = createAsyncThunk('partners/fetchPartners', async ({}, { rejectWithValue }) => {
  try {
    const response = await axiosRequest.get('/partners');
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const partnersSlice = createSlice({
  name: 'partners',
  initialState: {
    partners: [],
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartners.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchPartners.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.partners = action.payload;
      })
      .addCase(fetchPartners.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default partnersSlice.reducer;
