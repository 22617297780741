import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchStationDiagnostics = createAsyncThunk(
  'station/fetchStationDiagnostics',
  async ({ stationId }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/stations/${stationId}/diagnostics`);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const stationDiagnosticsSlice = createSlice({
  name: 'stationDiagnostics',
  initialState: {
    stationDiagnostics: [],
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStationDiagnostics.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchStationDiagnostics.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.stationDiagnostics = action.payload;
      })
      .addCase(fetchStationDiagnostics.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default stationDiagnosticsSlice.reducer;
