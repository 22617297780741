export const locationInitialState = {
  country: '',
  city: '',
  streetName: '',
  streetNumber: '',
  zipCode: '',
  directions: '',
  latitude: '',
  longitude: '',
  showAddress: true,
  name: '',
};

export const buildAddressText = (location) => {
  if (!location?.streetName) {
    return '';
  }
  return `${location?.streetName} ${location?.streetNumber}, ${location?.city}, ${location?.country}${
    location?.zipCode ? `, ${location?.zipCode}` : ''
  }`;
};

export const buildAddressLatLong = (location) => {
  if (!location?.latitude || !location?.longitude) {
    return '';
  }
  return `${location?.latitude}, ${location?.longitude}`;
};
