const TableBody = () => ({
  MuiTableBody: {
    styleOverrides: {
      root: {
        '.MuiTableRow-root:last-of-type': {
          '.MuiTableCell-root': {
            border: 'none',
          },
        },
      },
    },
  },
});

export default TableBody;
