import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchImportAndActivateStations = createAsyncThunk(
  'stations/fetchImportAndActivateStations',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.post('/import-register/stations', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const saveImportAndActivateStations = createAsyncThunk(
  'stations/saveImportAndActivateStations',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.post('/import-register/stations/save', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return {};
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const importAndActivateStationsSlice = createSlice({
  name: 'importAndActivateStations',
  initialState: {
    importAndActivateStations: {},
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    resetImportAndActivateStations: {
      reducer: (state) => {
        state.importAndActivateStations = {};
      },
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchImportAndActivateStations.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchImportAndActivateStations.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.importAndActivateStations = action.payload;
      })
      .addCase(fetchImportAndActivateStations.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(saveImportAndActivateStations.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(saveImportAndActivateStations.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.importAndActivateStations = action.payload;
      })
      .addCase(saveImportAndActivateStations.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetImportAndActivateStations } = importAndActivateStationsSlice.actions;

export default importAndActivateStationsSlice.reducer;
