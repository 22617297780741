import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { LAST_AUTH_PATH, loadFromLocalStorage, saveToLocalStorage } from 'utils';
import BackgroundLogin from 'assets/images/login_page.jpg';
import { theme } from 'themes/defaultTheme';
import { getLogoFromDomain } from 'utils/image';
import { useTranslation } from 'react-i18next';
import { CenteredContainer } from '../../components/common/container/CenteredContainer';
import { LocaleSelector } from '../../components/common/locale/LocaleSelector';

const TITLES = {
  '/auth/login': 'login.title',
  '/auth/reset-password': 'resetPassword.title',
  '/auth/forgot-password': 'forgotPassword.title',
};

export default function AuthLayout() {
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const [logoObject, setLogoObject] = useState();

  const isRegisterPage = window.location.pathname.includes('register');

  useEffect(() => {
    getLogoFromDomain(true).then((l) => setLogoObject(l));
  }, []);

  // useEffect(() => {
  //   let link = document.querySelector("link[rel~='icon']");
  //   if (!link) {
  //     link = document.createElement('link');
  //     link.rel = 'icon';
  //     document.getElementsByTagName('head')[0].appendChild(link);
  //   }
  //   link.href = 'https://stackoverflow.com/favicon.ico';
  //   document.title = 'dfsdfsdfsd';
  //   document.querySelector('meta[name="description"]').setAttribute('content', '22New Description');
  // }, []);

  useEffect(() => {
    if (user?.token) {
      const lastPath = loadFromLocalStorage(LAST_AUTH_PATH);

      if (lastPath) {
        saveToLocalStorage(LAST_AUTH_PATH, null);
        navigate(lastPath);
      } else {
        navigate('/dashboard');
      }
    } else {
      setIsReady(true);
    }
  }, [user?.token]);

  if (!isReady) {
    return null;
  }

  // TEMPORARY
  return (
    <Box>
      {!isRegisterPage && (
        <Grid container maxWidth="xl" mx="auto" height="100vh" alignItems="center">
          <Grid item sx={{ display: { xs: 'none', md: 'flex' }, paddingX: 2 }} md={6}>
            <img src={BackgroundLogin} alt="login" style={{ width: '100%', borderRadius: theme.shape.borderRadius }} />
          </Grid>

          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <Box>
              <div
                style={{
                  width: '250px',
                  borderBottom: '1px #e0e0e0 solid',
                  textAlign: 'center',
                  paddingBottom: '1rem',
                  display: 'inline-block',
                }}
              >
                <Link to="/auth/login">
                  {logoObject?.src && (
                    <img
                      src={logoObject?.src}
                      style={{ width: '200px', cursor: 'pointer' }}
                      alt={logoObject?.alt ?? ''}
                    />
                  )}
                </Link>
              </div>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: theme.customSpacing.spacingInsideFormSmall,
                }}
              >
                <LocaleSelector />
              </Box>

              <Typography variant="h2" component="h1" sx={{ mt: theme.customSpacing.spacingInsideFormSmall }}>
                {t(TITLES[window.location.pathname] ?? '')}
              </Typography>
            </Box>
            <Outlet />
          </Grid>
        </Grid>
      )}

      {isRegisterPage && (
        <Box
          sx={{
            backgroundColor: '#ebebeb',
            flex: 'row',
            flexGrow: 1,
            minHeight: '100vh',
          }}
        >
          <Box
            sx={{
              textAlign: 'center',
              padding: 2,
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              gap: 2,
            }}
          >
            <Box
              style={{
                height: '35px',
                display: 'inline-block',
              }}
            >
              <Link to="/auth/login">
                {logoObject?.src && (
                  <img
                    src={logoObject?.src}
                    style={{ width: '120px', cursor: 'pointer' }}
                    alt={logoObject?.alt ?? ''}
                  />
                )}
              </Link>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <LocaleSelector sx={{ backgroundColor: 'grey.100' }} />
            </Box>
          </Box>
          <CenteredContainer gridSize={12}>
            <Outlet />
          </CenteredContainer>
        </Box>
      )}
    </Box>
  );
}
