import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IApiGenericCallbackPayload,
  AsyncThunkConfig,
  ReduxStatusEnum,
  IPricingWeekProfile,
  IPricingWeekProfileApi,
} from 'types';
import { axiosRequest } from 'api/axiosRequest';
import { getPricingWeekProfileInitialItemsState } from 'utils/models/pricingWeekProfile';
import { hideWindowLoader, showWindowLoader } from '../ui/windowLoaderSlice';

interface IInitialState {
  list: IPricingWeekProfile[];
  item: IPricingWeekProfile;
  itemApi: IPricingWeekProfileApi;
  status: ReduxStatusEnum;
  error: unknown | string | null;
}

export const getAllPricingProfiles = createAsyncThunk<IPricingWeekProfile[], AsyncThunkConfig>(
  'pricingProfiles/getAll',
  async ({}, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/pricing-profiles`);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getPricingWeekProfile = createAsyncThunk<IPricingWeekProfileApi, { id: string }, AsyncThunkConfig>(
  'pricingWeekProfiles/getItem',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/pricing-profiles/${id}`);
      return { id, ...response.data };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createPricingWeekProfile = createAsyncThunk<
  IPricingWeekProfileApi,
  IApiGenericCallbackPayload<IPricingWeekProfileApi>,
  AsyncThunkConfig
>('pricingWeekProfiles/create', async ({ body, onSuccessCallback, onErrorCallback }, { dispatch, rejectWithValue }) => {
  try {
    dispatch(showWindowLoader({}));
    await axiosRequest.post(`/pricing-profiles`, body);
    onSuccessCallback && onSuccessCallback();
    dispatch(hideWindowLoader({}));

    return body as IPricingWeekProfileApi;
  } catch (e) {
    dispatch(hideWindowLoader({}));
    return rejectWithValue(e);
  }
});

export const updatePricingWeekProfile = createAsyncThunk<
  IPricingWeekProfileApi,
  IApiGenericCallbackPayload<IPricingWeekProfileApi> & { id: string },
  AsyncThunkConfig
>(
  'pricingWeekProfiles/update',
  async ({ id, body, onSuccessCallback, onErrorCallback }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(showWindowLoader({}));
      await axiosRequest.put(`/pricing-profiles/${id}`, body);
      onSuccessCallback && onSuccessCallback();
      dispatch(hideWindowLoader({}));

      return { ...body, id } as IPricingWeekProfileApi;
    } catch (e) {
      dispatch(hideWindowLoader({}));
      return rejectWithValue(e);
    }
  }
);

const pricingWeekProfilesSlice = createSlice({
  name: 'pricingWeekProfiles',
  initialState: {
    list: [] as IPricingWeekProfile[],
    item: {
      name: '',
      items: getPricingWeekProfileInitialItemsState(),
    } as IPricingWeekProfile,
    itemApi: {} as IPricingWeekProfileApi,
    status: ReduxStatusEnum.IDLE,
    error: null,
  } as IInitialState,
  reducers: {
    resetPricingWeekProfileApi: (state) => {
      state.itemApi = {} as IPricingWeekProfileApi;
    },
  },
  extraReducers: (builder) => {
    builder
      // get list
      .addCase(getAllPricingProfiles.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(getAllPricingProfiles.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.list = action.payload;
      })
      .addCase(getAllPricingProfiles.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // get item
      .addCase(getPricingWeekProfile.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(getPricingWeekProfile.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.itemApi = action.payload;
      })
      .addCase(getPricingWeekProfile.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // create
      .addCase(createPricingWeekProfile.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(createPricingWeekProfile.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
      })
      .addCase(createPricingWeekProfile.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // update
      .addCase(updatePricingWeekProfile.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(updatePricingWeekProfile.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.itemApi = { ...state.itemApi, ...action.payload };
      })
      .addCase(updatePricingWeekProfile.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetPricingWeekProfileApi } = pricingWeekProfilesSlice.actions;

export default pricingWeekProfilesSlice.reducer;
