import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import {
  IApiGenericCallbackPayload,
  AsyncThunkConfig,
  CreateUserPayload,
  ReduxStatusEnum,
  UpdateUserPayload,
  IUser,
} from 'types';

interface IPartnerUserState {
  user: IUser;
  status: ReduxStatusEnum;
  error: unknown | string | null;
}

const initialState: IPartnerUserState = {
  user: {} as IUser,
  status: ReduxStatusEnum.IDLE,
  error: null,
};

export const fetchPartnerUser = createAsyncThunk<IUser, { id: string }, AsyncThunkConfig>(
  'partnerUser/fetchPartnerUser',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/users/${id}`);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updatePartnerUser = createAsyncThunk<
  UpdateUserPayload,
  IApiGenericCallbackPayload<UpdateUserPayload>,
  AsyncThunkConfig
>('partnerUser/updatePartnerUser', async ({ body, onSuccessCallback }, { rejectWithValue }) => {
  try {
    if (!body) {
      throw new Error('No body provided');
    }
    await axiosRequest.put(`/users/${body.userId}`, {
      firstName: body.firstName,
      lastName: body.lastName,
      email: body.email,
      details: { phone: body.details.phone },
    });
    onSuccessCallback && onSuccessCallback();

    return {
      lastName: body.lastName,
      firstName: body.firstName,
      clientId: body.clientId,
      clientName: body.clientName,
      details: { phone: body.details.phone },
    };
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const createPartnerUser = createAsyncThunk<
  CreateUserPayload,
  IApiGenericCallbackPayload<CreateUserPayload>,
  AsyncThunkConfig
>('partnerUser/createPartnerUser', async ({ body, onSuccessCallback }, { rejectWithValue }) => {
  try {
    if (!body) {
      throw new Error('No body provided');
    }
    await axiosRequest.post(`/users/`, {
      email: body.email,
      firstName: body.firstName,
      lastName: body.lastName,
      clientId: body.clientId,
      role: 'admin',
      details: { phone: body.details.phone },
      sendEmail: true,
    });
    onSuccessCallback && onSuccessCallback();

    return {
      email: body.email,
      lastName: body.lastName,
      firstName: body.firstName,
      details: { phone: body.details.phone },
    };
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const partnerUserSlice = createSlice({
  name: 'partnerUser',
  initialState: { ...initialState },
  reducers: {
    resetPartnerUser: (state) => {
      state.user = {} as IUser;
      state.status = ReduxStatusEnum.IDLE;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch all partners
      .addCase(fetchPartnerUser.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchPartnerUser.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(fetchPartnerUser.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // update user partner
      .addCase(updatePartnerUser.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(updatePartnerUser.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(updatePartnerUser.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // create partner user
      .addCase(createPartnerUser.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(createPartnerUser.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(createPartnerUser.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetPartnerUser } = partnerUserSlice.actions;

export default partnerUserSlice.reducer;
