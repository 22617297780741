import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';
import { adaptClientFromListApiToSelect } from 'utils/models/client';

export const fetchClients = createAsyncThunk(
  'clients/fetchClients',
  async ({ lastName, firstName, email, phone, rfIds }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get('/clients');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchVisibleClients = createAsyncThunk(
  'clients/fetchVisibleClients',
  async ({ showCurrentAccountInList = false, userClientId }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/clients/all-visible?showCurrentAccount=${showCurrentAccountInList}`);
      const list = response.data;

      const sortClients = (a, b) => {
        if (a.id === userClientId) {
          return -1;
        }
        if (b.id === userClientId) {
          return 1;
        }
        return a.label > b.label ? 1 : -1;
      };

      const partners = list
        .filter((client) => client.level === 2 || (showCurrentAccountInList && client.id === userClientId))
        .map((item) => {
          return adaptClientFromListApiToSelect(item, userClientId);
        })
        .sort(sortClients);

      const clients = list
        .filter((client) => client.level === 1 || (showCurrentAccountInList && client.id === userClientId))
        .map((item) => {
          return adaptClientFromListApiToSelect(item, userClientId);
        })
        .sort(sortClients);

      const tenant = adaptClientFromListApiToSelect(list.find((client) => client.level === 3) ?? {});

      return { clients, partners, tenant, all: list };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    clients: [],
    visibleClients: [],
    visiblePartners: [],
    allClients: [],
    visibleAll: [],
    tenant: {},
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.clients = action.payload;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(fetchVisibleClients.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchVisibleClients.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.visibleClients = action.payload.clients;
        state.visiblePartners = action.payload.partners;
        state.allClients = action.payload.all;
        state.visibleAll = action.payload.tenant?.id
          ? [...action.payload.clients, ...action.payload.partners, action.payload.tenant]
          : [...action.payload.clients, ...action.payload.partners];
        state.tenant = action.payload.tenant;
      })
      .addCase(fetchVisibleClients.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default clientsSlice.reducer;
