const Select = () => ({
  MuiSelect: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        marginTop: '8px',
        marginBottom: '4px',
        width: '100%',
      },
    },
  },
});

export default Select;
