import { SxProps } from '@mui/material';
import { theme } from '../themes/defaultTheme';

export const getInputBoxStyle = (isFlexRow: boolean, disabled = false, extraStyle: SxProps = {}): SxProps => {
  if (!isFlexRow && disabled) {
    return {};
  }
  if (!isFlexRow) {
    return {
      width: '100%',
      ...extraStyle,
    };
  }

  return {
    display: 'flex',
    width: '100%',
    flexDirection: isFlexRow ? 'row' : 'column',
    justifyContent: 'flex-start',
    alignItems: isFlexRow ? 'center' : 'flex-start',
    gap: isFlexRow ? 1 : 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 0,
    },
    ...extraStyle,
  };
};

export const getLabelStyle = (isFlexRow: boolean, size: 'lg' | 'md' = 'lg', customStyle: SxProps = {}): SxProps => {
  const base = {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  };
  if (!isFlexRow) {
    return { ...base, ...customStyle };
  }

  return {
    ...base,
    minWidth: '200px',
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      width: '100%',
    },
    ...customStyle,
  };
};

export const getValueStyle = (isFlexRow: boolean, size: 'lg' | 'md' = 'lg', customStyle: SxProps = {}): SxProps => {
  const props = {
    fontSize: size === 'lg' ? '15px' : '12px',
  };
  if (!isFlexRow) {
    return { ...props, ...customStyle };
  }

  return {
    ...props,
    width: '100%',
    ...customStyle,
  };
};

export const getInputValueStyle = (isFlexRow: boolean, size: 'lg' | 'md' = 'lg'): SxProps => {
  return {
    fontSize: size === 'lg' ? '18px' : '14px',
    width: '100%',
  };
};

export const getSwitchInputStyle = (isFlexRow: boolean): SxProps => {
  if (!isFlexRow) {
    return {
      ...getInputValueStyle(isFlexRow),
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    };
  }

  return getInputValueStyle(isFlexRow);
};

export const getSwitchBoxStyle = (isFlexRow: boolean): SxProps => {
  if (!isFlexRow) {
    return {
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
      },
    };
  }

  return {
    ...getInputBoxStyle(isFlexRow),
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  };
};
