import { CustomTheme } from '../../types';

const TabScrollButton = (theme: CustomTheme) => ({
  MuiTabScrollButton: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
});

export default TabScrollButton;
