import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchImportCards = createAsyncThunk('cards/fetchImportCards', async (formData, { rejectWithValue }) => {
  try {
    const response = await axiosRequest.post('/import/cards/false', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const saveImportCards = createAsyncThunk('cards/saveImportCards', async (formData, { rejectWithValue }) => {
  try {
    const response = await axiosRequest.post('/import/cards/save', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return {};
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const resetImportCards = createAsyncThunk('cards/resetImportCards', async () => {
  return {};
});

export const importCardsSlice = createSlice({
  name: 'importCards',
  initialState: {
    importCards: {},
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchImportCards
      .addCase(fetchImportCards.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchImportCards.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.importCards = action.payload;
      })
      .addCase(fetchImportCards.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // saveImportCards
      .addCase(saveImportCards.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(saveImportCards.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.importCards = action.payload;
      })
      .addCase(saveImportCards.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // resetImportCards
      .addCase(resetImportCards.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(resetImportCards.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.importCards = action.payload;
      })
      .addCase(resetImportCards.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default importCardsSlice.reducer;
